import React from 'react'
import { Component } from '../../../types'
import Text from './Text'
import Video from './Video'
import PhotoCollage from './PhotoCollage'
import styled from 'styled-components'
import Title from '../Titles'
import colors from '../../utils/colors.json'
import { deletePostComponent } from '../../utils/firebase'
import { DeleteFilled } from '@ant-design/icons'

const Wrapper = styled.div`
  margin: 25px 0;
  background-color: ${colors.GM_Grey};
  border-radius: 10px;
  padding: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Spacer = styled.div`
  flex: 1;
`

interface WrapperProps {
  title: string
  postID: string
  component: Component
  children: any
}

export const ComponentWrapper: React.FC<WrapperProps> = ({ children, title, postID, component }) => {
  const onDelete = async (): Promise<void> => {
    try {
      await deletePostComponent(postID, component)
    } catch (e) {
      alert(e)
    }
  }

  return (
    <Wrapper>
      <Row>
        <Title style={{ margin: 0 }}>{title}</Title>
        <Spacer />
        <DeleteFilled onClick={onDelete} style={{ padding: 10, cursor: 'pointer' }} size={30} color={colors.GM_Warning} />
      </Row>
      {children}
    </Wrapper>
  )
}

export interface EditPostComponentProps {
  component: Component
  postID: string
}

const EditPostComponent: React.FC<EditPostComponentProps> = ({ component, postID }) => {
  switch (component.type) {
    case 'videoplayer':
      return <Video component={component} postID={postID} autoplay={component.autoplay} />
    case 'photoCollage':
      return <PhotoCollage component={component} postID={postID} />
    case 'photo360':

      break
    case 'text':
      return <Text component={component} postID={postID} />
  }

  return (
    <ComponentWrapper title='Not implemented yet' component={component} postID={postID}>
      <div>
        Not implemented yet...
      </div>
    </ComponentWrapper>
  )
}

export default EditPostComponent
