import React from 'react'
import styled from 'styled-components'
import { Post } from '../../types'
import isMobile from 'is-mobile'

interface ThumbImage {
  highlighted: number
}
interface ProjectThumbnailProps {
  post: Post
  highlighted?: number
  height?: number | string
  width?: number | string
  onClick: (e: React.UIEvent<HTMLDivElement>) => void
}

export const thumbnailHeight = 400

const StyledThumbnail = styled.div<ThumbImage>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin: 1rem 0;
  &:hover{
    opacity: 1;
  }
  box-sizing: border-box;
  transform: scale(${(props: ThumbImage) => isMobile() ? 0.8 : Math.max(1 - props.highlighted * 0.09, 0.8)});
  opacity: ${(props: ThumbImage) => isMobile() ? 1 : Math.max(1 - props.highlighted * 0.42, 0.05)};
 
  @media only screen and (max-width: 750px){    
    filter: grayscale(0);
    opacity: 1;
    transform: scale(1);
   
  }
  transition: all 0.7s ease;
  
`

interface StyledImageProps {
  uri: string
  height: number | string
  width?: number | string
  color?: string
}

const StyledImage = styled.div<StyledImageProps>`
  display: flex;
  position: relative;
  border-radius: 10px;
  height: ${(props: StyledImageProps) => typeof props.height === 'string' ? props.height : `${props.height}px`};
  width: ${(props: StyledImageProps) => typeof props.width === 'number' ? `${props.width}px` : props.width ?? 'auto'};
  background-image: ${(props: StyledImageProps) => `url(${props.uri})`};
  background-size: cover;
  background-position: center;
  flex-flow: column;
`

const ProjectThumbnail: React.FC<ProjectThumbnailProps> = ({ post, highlighted = 0, onClick, height = 350, width = 200 }) => {
  return (
    <StyledThumbnail highlighted={highlighted} onClick={onClick}>
      <StyledImage height={height} uri={post.thumbnail} width={width} />
    </StyledThumbnail>
  )
}

export default ProjectThumbnail
