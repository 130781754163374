import React from "react"
import styled from "styled-components"
import Colors from "../utils/colors.json"

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: Capitalize;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0px;
  color: ${Colors.GM_Grey_Light};
  transition: 200ms;
  margin: 5px 0;
  cursor: pointer;

  :hover {
    color: ${Colors.GM_Black2};
  }
`

const Spacer = styled.div`
  display: flex;
  flex: 1;
  width: 100px;
`

export interface ButtonProps {
  onClick: () => void
  style?: React.CSSProperties
  type?: "warning"
  color?: string
  children: any
}

const ButtonThinn: React.FC<ButtonProps> = ({
  children,
  style,
  onClick,
  type,
  color = Colors.GM_Orange,
}) => {
  return (
    <Row>
      <Spacer />
      <ButtonElement
        color={type === "warning" ? Colors.GM_Warning : Colors.GM_Black2}
        style={{ ...style }}
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
      >
        {children}
      </ButtonElement>
    </Row>
  )
}

export default ButtonThinn
