import { getApp } from "firebase/app"
import { collection, doc, getFirestore } from "firebase/firestore"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Post } from "../../../types"
import CardTilt from "../../components/CardTilt"
import Loading from "../../components/Loading"
import ProjectPreview from "../../components/ProjectPreview"
import ProjectThumbnail from "../../components/ProjectThumbnail"
import Mobile from "./Mobile"

const pi = Math.PI
const faceCount = 30
const faceHeight = 84
const faceDegrees = 360 / faceCount
const faceShift = faceHeight / 2 / Math.tan((faceDegrees / 2) * (pi / 180))

const CylinderContainer = styled.div<{ bg: string }>`
  background: ${(props) => props.bg};

  transition: background 1s ease-in-out;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Cylinder = styled.div<{ rotation: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  min-height: 100vh;
  transform-style: preserve-3d;
  transform: rotateX(${(props: { rotation: number }) => props.rotation}deg)
    translateX(0px);
  transition: transform 0.33s cubic-bezier(0.25, 0.56, 0.7, 0.94);
  z-index: 1;
  pointer-events: auto;
  width: 95vw;
  max-width: 2200px;
`

const Face = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 70px;
  width: 50vw;
  align-items: flex-start;
  justify-content: center;
  transform-style: preserve-3d;
  transform: rotateX(
      ${(props: { index: number }) => -props.index * faceDegrees}deg
    )
    translateZ(${faceShift}px);
  backface-visibility: hidden;
  pointer-events: auto;
  overflow: wrap;
  min-width: min-content;
  width: 100%;
`

const Parallax = styled.div`
  perspective: 1000px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  z-index: 3;
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
`
const ParallaxLayerThumbnail = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  right: 0;
  transform: translateZ(0) scale(1);
  padding: 52vh 0;
  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  &:after {
    position: absolute;
    perspective-origin: 100%;
  }
  z-index: 2;
`

const PaginationDots = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
`
const PaginationDot = styled.div<{ active: boolean; color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props: { active: boolean; color: string }) =>
    props.active ? props.color : "gray"};
  margin: 3px 3px;
  opacity: ${(props: { active: boolean }) => (props.active ? 1 : 0.2)};
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
// const Blurry = styled.div`
//   position: absolute;
//   pointer-events: none;
//   z-index: 100;
//   top: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.2);
//   backdrop-filter: blur(4px);
//   -webkit-mask: linear-gradient(
//     0deg,
//     white 2%,
//     transparent 45%,
//     transparent 55%,
//     white 98%
//   );
// `
const getRefDimenstions = (
  ref: React.RefObject<HTMLDivElement>
): { width: number; height: number } | null => {
  if (ref.current != null) {
    const { current } = ref
    if (Array.from(current.children).length === 0) return null

    const clientHeight =
      Array.from(current.children)[0].clientHeight -
      Number(
        window
          .getComputedStyle(Array.from(current.children)[0])
          .marginTop.replace("px", "")
      ) +
      Number(
        window
          .getComputedStyle(Array.from(current.children)[0])
          .marginBottom.replace("px", "")
      )
    return {
      width: Math.round(clientHeight),
      height: Math.round(clientHeight),
    }
  } else {
    return null
  }
}

const Case: React.FC = () => {
  useEffect(() => {
    document.title = "Ghost Motion - Case"
  }, [])
  const [firebasePosts] = useCollection(
    collection(getFirestore(getApp()), "posts")
  )
  const posts = firebasePosts?.docs
    ?.map((doc) => doc.data())
    .filter((p) => p.published) as Post[] | undefined
  const [firebasePostsOrder] = useDocumentData(
    doc(getFirestore(getApp()), "sorting", "posts")
  )
  const postOrdering = firebasePostsOrder?.order as string[]
  const orderedPosts = postOrdering
    ?.map((id) => posts?.find((p) => p.id === id))
    .filter((p) => p != null) as Post[] | undefined
  if (orderedPosts == null) {
    return <Loading />
  }
  return <CaseInner posts={orderedPosts} />
}

const CaseInner: React.FC<{ posts: Post[] }> = ({ posts }) => {
  const [offset, setOffset] = useState(0)
  const navigate = useNavigate()
  const thumbnailHeightRef = useRef<HTMLDivElement>(null)
  const [thumbnailHeight, setThumbnailHeight] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const activePost = posts[offset]
  const currentThumbnailHeight = screenWidth * 0.225
  const [rotateAmount, setRotateAmount] = useState(0)
  const scroller = useRef<HTMLDivElement>(null)
  const [color1, setColor1] = useState("#000")

  const updateHeight = useCallback((): void => {
    const dim = getRefDimenstions(thumbnailHeightRef)
    if (dim != null) {
      setThumbnailHeight(dim.height)
    }
  }, [])

  const handleScroll = useCallback(
    (scrollTop: number) => {
      const scrollPosition = scrollTop
      const currentThumbnailHeight = thumbnailHeight
      const index = Math.floor(scrollPosition / currentThumbnailHeight)
      setRotateAmount(index * faceDegrees)
      if (posts == null) return
      setOffset(index)
    },
    [posts, thumbnailHeight]
  )

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener("resize", (e) => {
      setScreenWidth(window.innerWidth)
      const dim = getRefDimenstions(thumbnailHeightRef)
      if (dim != null) {
        setThumbnailHeight(dim.height)
      }
      updateHeight()
    })
  }, [updateHeight])

  useEffect(() => {
    handleScroll(0)
  }, [handleScroll, posts])

  useEffect(() => {
    if (thumbnailHeightRef == null) return
    updateHeight()
    if (activePost == null) return
    const color1 = activePost.titleColor
    setColor1(color1)
  }, [thumbnailHeightRef, updateHeight, posts, offset, activePost, color1])

  const scrollToProject = (index: number) => () => {
    const currentThumbnailHeight = thumbnailHeight
    const scrollPosition = index * currentThumbnailHeight
    if (scroller.current == null) return
    scroller.current.scroll({
      top: scrollPosition + thumbnailHeight,
      behavior: "smooth",
    })
  }

  if (screenWidth < 750) {
    return <Mobile posts={posts} />
  }

  return (
    <>
      <PaginationDots>
        {posts?.map((post, index) => (
          // scroll to post
          <PaginationDot
            key={post.id}
            active={index === offset}
            onClick={scrollToProject(index)}
            color={post.titleColor}
          />
        ))}
      </PaginationDots>
      <CylinderContainer bg={color1}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            background:
              "radial-gradient(ellipse farthest-side at top right, rgba(255,255,255,0.5) 0%,  rgba(255,255,255,0) 100%)",
          }}
        />
        <Cylinder rotation={rotateAmount}>
          {posts?.map((post, index) => (
            <Face index={index} key={post.id}>
              <ProjectPreview
                highlighted={activePost?.id === post.id}
                post={post}
                key={post.id}
                onClick={() => navigate("/post/" + post.id)}
              />
            </Face>
          ))}
        </Cylinder>
      </CylinderContainer>
      {/* <Blurry /> */}
      <Parallax
        onScroll={(e) => handleScroll(e.currentTarget.scrollTop)}
        ref={scroller}
      >
        <ParallaxLayerThumbnail
          style={{ overflow: "visible" }}
          ref={thumbnailHeightRef}
          onLoad={(e) => handleScroll(0)}
        >
          {posts?.map((post, index) => (
            <CardTilt key={post.id}>
              <ProjectThumbnail
                post={post}
                highlighted={Math.abs(index - offset)}
                onClick={() => navigate("/post/" + post.id)}
                height={currentThumbnailHeight}
                width={currentThumbnailHeight * 1.77}
              />
            </CardTilt>
          ))}
        </ParallaxLayerThumbnail>
      </Parallax>
    </>
  )
}

export default Case
