import { getApp } from "firebase/app"
import { collection, doc, getFirestore } from "firebase/firestore"
import React, { useContext, useMemo } from "react"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Component, Post as PostType } from "../../types"
import { IsAdminContext } from "../App"
import Button from "../components/Button"
import Footer from "../components/Footer"
import { headerHeight } from "../components/Header"
import Loading from "../components/Loading"
import PostComponent from "../components/postComponents"
import { Text } from "../components/Titles"
import { sortByID } from "../utils/sorting"
import isMobile from "is-mobile"

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: ${headerHeight}px;
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`

const ProjectTitletext = styled.div<{
  color1: string
  color2: string
}>`
  margin-top: 2rem;
  font-size: ${isMobile() ? "32px" : "52px"};
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
  pointer-events: none;
  background: ${(props: { color1: string }) => props.color1};
  background: radial-gradient(
    ellipse farthest-side at top right,
    ${(props: { color2: string }) => props.color2} 0%,
    ${(props: { color1: string }) => props.color1} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 0.1em;
`

const Post: React.FC = () => {
  const { id } = useParams()
  const [firebasePost, loadingPost, errorPost] = useDocument(
    doc(getFirestore(getApp()), "posts/" + String(id))
  )
  const post = firebasePost?.data() as PostType

  const [firebaseComponents, loadingComponents, errorComponents] =
    useCollection(
      collection(
        doc(getFirestore(getApp()), "posts/" + String(id)),
        "components"
      )
    )
  const componentOrder = useMemo(() => post?.componentOrder, [post])
  const unsortedComponents = firebaseComponents?.docs.map((doc) =>
    doc.data()
  ) as Component[] | undefined
  const components: Component[] =
    unsortedComponents != null
      ? sortByID(unsortedComponents, componentOrder)
      : []
  const isAdmin = useContext(IsAdminContext)
  const navigate = useNavigate()

  function changeColor(color: string, amount: number): string {
    // #FFF not supportet rather use #FFFFFF
    const clamp = (val: any): Number => Math.min(Math.max(val, 0), 0xff)
    const fill = (str: string): string => ("00" + str).slice(-2)

    // convert to color string to number
    const num = parseInt(color?.substring(1), 16)
    const red = clamp((num >> 16) + amount)
    const green = clamp(((num >> 8) & 0x00ff) + amount)
    const blue = clamp((num & 0x0000ff) + amount)
    return (
      "#" +
      fill(red.toString(16)) +
      fill(green.toString(16)) +
      fill(blue.toString(16))
    )
  }

  if (loadingPost) {
    return <Loading />
  }

  if (post == null) {
    return <p>Post not found</p>
  }

  if (loadingComponents) {
    return <Loading />
  }

  if (errorComponents != null) {
    return <p>Error loading components</p>
  }

  if (errorPost != null) {
    return <p>Error loading post</p>
  }

  if (components == null || components?.length < 1) {
    return <p>This project is empty</p>
  }

  const allComponents = components.splice(1, components.length - 1)

  return (
    <>
      <Center>
        <ProjectContainer>
          {isAdmin && (
            <Button
              style={{ marginBottom: 20 }}
              onClick={() => navigate("/admin/post/" + post.id)}
            >
              Edit this post
            </Button>
          )}
          <PostComponent component={components[0]} />
          <ProjectTitletext
            color1={post.titleColor}
            color2={changeColor(post.titleColor, 70)}
          >
            {post?.title}
          </ProjectTitletext>
          <Text>{post?.description}</Text>
          {allComponents?.map((component) => (
            <PostComponent key={component.id} component={component} />
          ))}
        </ProjectContainer>
      </Center>
      <Footer />
    </>
  )
}

export default Post
