import React from "react"
import styled from "styled-components"
import Footer from "../../components/Footer"
import Title from "../../components/Titles"
import Button from "../../components/Button"
import { Post } from "../../../types"
import ProjectThumbnail from "../../components/ProjectThumbnail"
import { useNavigate } from "react-router-dom"
// import ButtonCta from '../../components/ButtonCta'

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  position: static;
  overflow-x: hidden;
  height: auto;
  width: 100vw;
  scroll-snap-type: y mandatory;
  overflow-y: hidden;
`
const PostContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin-bottom: 4rem;
  padding: 0 1rem;
  margin: 0 1rem 4rem;
  border-radius: 1rem;
  cursor: pointer;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`
const TitleWrapper = styled.div`
  display: flex;
  width: 90vw;
  margin-top: 1.6em;
  font-size: 52px;
  font-weight: bold;
  text-transform: uppercase;
`
const ProjectTitletext = styled.div<{ color1: string; color2: string }>`
  margin: 0;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
  background: ${(props: { color1: string }) => props.color1};
  background: radial-gradient(
    ellipse farthest-side at top right,
    ${(props: { color2: string }) => props.color2} 0%,
    ${(props: { color1: string }) => props.color1} 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
const Year = styled.div`
  font-size: clamp(12px, 1vw, 16px);
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
`

export interface MobileProps {
  posts: Post[]
}

const Mobile: React.FC<MobileProps> = ({ posts }) => {
  const navigate = useNavigate()

  function changeColor(color: string, amount: number): string {
    // #FFF not supportet rather use #FFFFFF
    const clamp = (val: any): Number => Math.min(Math.max(val, 0), 0xff)
    const fill = (str: string): string => ("00" + str).slice(-2)

    // convert to color string to number
    const num = parseInt(color?.substring(1), 16)
    const red = clamp((num >> 16) + amount)
    const green = clamp(((num >> 8) & 0x00ff) + amount)
    const blue = clamp((num & 0x0000ff) + amount)
    return (
      "#" +
      fill(red.toString(16)) +
      fill(green.toString(16)) +
      fill(blue.toString(16))
    )
  }
  return (
    <CardContainer>
      <TitleWrapper>Case</TitleWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100vw",
        }}
      >
        {posts?.map((post, index) => (
          <PostContainer
            key={post.id}
            color={post.titleColor}
            onClick={() => navigate("/post/" + post.id)}
          >
            <ProjectThumbnail
              post={post}
              onClick={() => {}}
              height='30vh'
              width='auto'
            />
            <Info>
              <ProjectTitletext
                color1={post.titleColor}
                color2={changeColor(post.titleColor, 70)}
              >
                {post.title}
              </ProjectTitletext>
              <Details>
                <Row>{post.stats.clientName}</Row>
                <Year>{new Date(post.stats.end).getFullYear()}</Year>
              </Details>
            </Info>
          </PostContainer>
        ))}
      </div>
      <Column>
        <div style={{ margin: "0 2rem" }}>
          <Title>Have a project in mind?</Title>
          <Button onClick={() => navigate("/contact")}>Get to know us</Button>
          {/* <ButtonCta onClick={() => navigate('/contact')}>Get to know us</ButtonCta> */}
        </div>
        <Footer />
      </Column>
    </CardContainer>
  )
}

export default Mobile
