import React from "react"

const GhostIcon: React.FC = () => (
  <svg viewBox='0 0 778.59 1079.93' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M773.48 435.54c-10.83-117.27-39-232.81-118.1-321.87C531.88-25.34 291.45-69.42 165.43 171c-60.59 115.57-88.79 242-117 368.8C34.82 600.84 29.68 663 14.65 724c-18.63 75.56-20.24 155-.51 231.39 20.81 80.55 67.13 91.51 124.79 33.15 21.55-21.82 37.39-27.36 55.84 5 34.8 61 68.27 61.09 108.13 3.85 12.47-17.9 19.08-43.19 50-39.68s30.63 30.23 40.05 49.87c3.3 6.88 5.22 14.4 8.16 21.46 9.51 22.92 19.4 45.73 48 50.27 30.8 4.89 42.67-18.22 56.31-38.61 33.33-49.79 44-51.41 88.92-13.64 60.87 51.15 92.2 42.68 120.52-31.51 31.87-83.46 39.49-172 55.79-253.13 7.3-106.03 12.1-206.63 2.83-306.88zm-323.72-48.41c-3.89 28.72-20 77.52-55.42 77.52-14.18 0-21.84-18.56-25.86-29.37-7.12-19.16-5.6-41.59-2.9-61.48 3.89-28.73 20-77.52 55.42-77.52 14.18 0 21.85 18.56 25.86 29.37 7.12 19.16 5.6 41.59 2.9 61.48zm214.61 17.17c-3.9 28.72-20 77.52-55.43 77.52-14.18 0-21.84-18.56-25.86-29.37-7.11-19.16-5.59-41.59-2.9-61.48 3.89-28.73 20-77.52 55.43-77.52 14.17 0 21.84 18.56 25.86 29.37 7.11 19.18 5.59 41.59 2.9 61.48z'
      fill='#fff'
    />
  </svg>
)

export default GhostIcon
