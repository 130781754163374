import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Input from './Input'
import Alert from './Alert'
import { updateProfile } from '../utils/firebase'
import { IsAdminContext } from '../App'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading'
import ButtonThinn from './ButtonThinn'
import Colors from '../utils/colors.json'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: absolute;
`

const Content = styled.div`
  display: flex;
  
  width: clamp(300px, 50%, 350px);
  align-items: stretch;
  flex-direction: column; 
  padding: 20px;
  border-radius: 10px;
`

const Frame = styled.div`
  margin: 60px;
`

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const auth = getAuth()
  const [error, setError] = useState<string | null>(null)
  const isAdmin = useContext(IsAdminContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAdmin) {
      navigate('/admin')
    }
  }, [isAdmin, navigate])

  const login = (): void => {
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      setError(error.message.split('Firebase: ')[1])
    })
  }

  const register = (): void => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // const user = userCredential.user
        // Will automatically redirect
        if (userCredential.user.email != null) {
          updateProfile({ id: userCredential.user.uid, email: userCredential.user.email }).catch(() => {
            alert('Error creating user')
          })
        }
      })
      .catch((error) => {
        setError(error.message.split('Firebase: ')[1])
      })
  }

  const forgotPassword = (): void => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setError('Password reset email sent')
      }).catch((e) => setError(e.message.split('Firebase: ')[1]))
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      login()
    }
  }
  return (
    <Container>
      <Content>
        <Frame>
          <Loading style={{ width: '100px' }} />
        </Frame>
        {error != null && <Alert type='warning'>{error}</Alert>}
        <Input value={email} onChange={setEmail} placeholder='email' onKeyDown={handleKeyPress} style={{ background: '#000', padding: '10px' }} />
        <Input value={password} onChange={setPassword} placeholder='password' type='password' onKeyDown={handleKeyPress} style={{ background: Colors.GM_Solid_Black, marginTop: '10px', padding: '10px' }} />
        <ButtonThinn onClick={forgotPassword}>Forgot password?</ButtonThinn>
        <Button onClick={login}>Login</Button>
        <Button onClick={register}>Register</Button>

      </Content>
    </Container>
  )
}

export default Login
