import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CopyOutlined } from '@ant-design/icons'
import CardTilt from '../components/CardTilt'
import Footer from '../components/Footer'
import { headerHeight } from '../components/Header'
import Loading from '../components/Loading'
import Colors from '../utils/colors.json'
import ComponentIcon from '../components/icons/customIcons'

const PageWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const Section = styled.div`
  position: relative;
  width: 100vw;
  height: 100svh;
  box-sizing: border-box;
  display: flex;
  justify-content: center; 
  align-items: flex-start; 
`
const Centering = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const ContentBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  max-height: 800px;
  width: 80vw;
  max-width: 1400px;
  // background: radial-gradient(circle at 50% -100%, #282828, #1a1a1a);
`
const ContentBox2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20vh;
  @media only screen and (max-width: 600px){
    align-items: flex-start;
    margin-top: ${headerHeight}px;
  }
`
const ArrowDown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 25px;
`
const BigText = styled.div`
  position: relative;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
`
const Heading = styled.div`
  position: relative;
  display: flex; 
  flex-direction: column; 
  width: 90vw;
  max-width: 1600px;
  @media only screen and (max-width: 600px){
    box-sizing: border-box;
    padding-top: 68px;
  }
  transition: 200ms;
`
const VeryLarge = styled.div`
  position: relative;
  font-size: 64px;
  text-transform: uppercase;
  font-weight: bold;
  @media only screen and (max-width: 600px){
    font-size: 32px;
  }
`
const VeryLargeItalics = styled.div`
  position: relative;
  font-size: 64px;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  @media only screen and (max-width: 600px){
    font-size: 43px;
  }
`
const SmallText = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
`
const SmallText2 = styled.div`
  position: relative;
  font-weight: regular;
  font-size: 20px;
  width: 50vw;
  max-width: 666px;
  transition: 200ms;
  @media only screen and (max-width: 600px){
    width: 90vw;
    font-size: 16px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50vw;
  max-width: 666px;
  margin-top: 20px;
  

  @media only screen and (max-width: 600px){
    flex-direction: column;
    width: 90vw;
    align-items: flex-start;
  }
`
const StyledLink = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  font-size: 20px; 
  font-weight: bold;
  text-decoration: none !important;
  background-color: ${Colors.GM_Black2};
  box-sizing: border-box;
  border-radius: 7px;
  padding: 10px;
  width: 350px;
  justify-content: space-between;
  transition: 200ms;
  :hover{
    background-color: ${Colors.GM_Grey_Light};
  }
`
const EmailText = styled.a`
  text-decoration: none !important;
  color: #fff;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  `

const CopiedText = styled.div<{ copied: number }>`
  position: relative;
  font-size: 15px;
  color: #15E94A;
  margin-left: 10px;
  transition: 200ms;
  opacity: ${(props: { copied: number }) => props.copied};
  transform: ${(props: { copied: number }) => props.copied === 1 ? 'translateY(10px)' : 'translateY(0px)'};
  @media only screen and (max-width: 600px){
    transform: ${(props: { copied: number }) => props.copied === 1 ? 'translateY(10px)' : 'translateY(0px)'};
  }
`

const InsideButton = styled.div<{ copied: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  border-radius: 9px;
  padding: 7px;
  background-color: #252525;

  color: ${(props: { copied: boolean }) => props.copied ? '#15E94A' : '#ABABAB'};
  transition: 200ms;
  :hover{
    color: ${(props: { copied: boolean }) => props.copied ? '#15E94A' : '#FFF'};
    cursor: pointer;
  }
`
const SmallText3 = styled.div`
  position: relative;
  font-weight: regular;
  font-size: 10px;
  margin-left: 5px;
`
const About: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    document.title = 'Ghost Motion - Contact'
  }, [])
  // const navigate = useNavigate()
  const [copied, setCopied] = useState(false)
  const textRef = useRef<HTMLAnchorElement>(null)
  const copyToClipboard = async (): Promise<void> => {
    if (textRef.current !== null) {
      const text = textRef.current.innerText
      await
      navigator.clipboard.writeText(text)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }
  const scrollToSection = (): void => {
    if (sectionRef.current !== null) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <PageWrapper>
      <Section>
        <Centering>
          <CardTilt>
            <ContentBox>
              <BigText>Hello</BigText>
              <SmallText>We are ghost motion</SmallText>
              <Loading style={{ width: '100px' }} />
            </ContentBox>
          </CardTilt>
        </Centering>
        <ArrowDown>
          <ComponentIcon component='ChevronDown' fillColor='white' style={{ marginBottom: '1rem', cursor: 'pointer' }} size={42} onClick={scrollToSection} />
        </ArrowDown>
      </Section>
      <Section ref={sectionRef}>
        <ContentBox2>
          <Heading>
            <VeryLarge>About</VeryLarge>
            <VeryLargeItalics>Ghost Motion</VeryLargeItalics>
          </Heading>
          <SmallText2>
            Ghost Motion specializes in film, photography and design – but are happy to work with all types of graphic communication. Under Case, you can see a selection of what we offer.

            Would you like to know more about us, our work or maybe just have a cup of coffee?
            Feel free to shoot us an email, we'd love to work with you. 
          </SmallText2>
          <ButtonWrapper>
            <StyledLink>
              <EmailText href='mailto:hello@ghostmotion.se?subject=Hello!' ref={textRef}>hello@ghostmotion.se</EmailText>
              <InsideButton copied={copied} onClick={copyToClipboard}>
                <CopyOutlined />
                <SmallText3>Copy</SmallText3>
              </InsideButton>
            </StyledLink>
            <CopiedText copied={copied ? 1 : 0}>Copied to clipboard</CopiedText>
          </ButtonWrapper>
        </ContentBox2>
      </Section>
      {/* <Section>
        <Centering>
          <BigText style={{ marginRight: '100px' }}>
            Check out
          </BigText>
          <BigText style={{ marginLeft: '100px' }}>
            what we do
          </BigText>
          <Button onClick={() => navigate('/case')}>Our Cases</Button>
        </Centering>
      </Section> */}
      <Footer />
    </PageWrapper>
  )
}

export default About
