import React from 'react'
import styled from 'styled-components'
import { ArrowRightOutlined } from '@ant-design/icons'
import Colors from '../utils/colors.json'
import ComponentIcon, { CustomIconType } from './icons/customIcons'

const ButtonElement = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  color: ${Colors.GM_Solid_White};
  background-color: ${(props) => props.color};
  transition: 200ms;
  cursor: pointer;
  border-radius: 5px;
  gap: 1rem;
`

export interface CustomIcon {
  size: number
  component: React.FC
  style?: React.CSSProperties
}

export interface ButtonProps {
  onClick: () => void
  style?: React.CSSProperties
  styletype?: 'warning'
  type?: string
  color?: string
  iconType?: React.ForwardRefExoticComponent<any>
  children: React.ReactNode
  customIcon?: CustomIconType
  iconSize?: number
}

const Button: React.FC<ButtonProps> = ({
  children,
  style,
  onClick,
  type,
  color = 'white',
  iconType = ArrowRightOutlined,
  customIcon = 'RightArrow',
  iconSize = 24
}) => {
  return (
    <ButtonElement
      color={type === 'warning' ? Colors.GM_Warning : Colors.GM_Solid_Black}
      style={{ ...style }}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
    >
      {children}
      <ComponentIcon fillColor={color} size={iconSize} component={customIcon} />
    </ButtonElement>
  )
}

export default Button
