import React from "react"
import styled from "styled-components"
import Colors from "../utils/colors.json"
import FacebookIcon from "./icons/FacebookIcon"
import InstagramIcon from "./icons/InstagramIcon"
import LinkedInIcon from "./icons/LinkedInIcon"
import VimeoIcon from "./icons/VimeoIcon"

const FooterContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
`
const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-top: 100px;
`

const StyledLink = styled.a`
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: 200ms;
  padding: 10px;
  border-radius: 7px;
  &:hover {
    background-color: ${Colors.GM_Grey};
  }
`

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  min-width: 200px;
  text-align: center;
  text-transform: capitalize;
  margin-top: 80px;
  color: #727272;
  font-size: 12px;
`
const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Socials>
        <StyledLink
          href='https://www.facebook.com/ghostmotion.se'
          target='_blank'
          rel='noreferrer'
        >
          <FacebookIcon style={{ width: 30 }} />
        </StyledLink>
        <StyledLink
          href='https://vimeo.com/ghostmotion'
          target='_blank'
          rel='noreferrer'
        >
          <VimeoIcon style={{ width: 30 }} />
        </StyledLink>
        <StyledLink
          href='http://instagram.com/ghostmotion.se'
          target='_blank'
          rel='noreferrer'
        >
          <InstagramIcon style={{ width: 30 }} />
        </StyledLink>
        <StyledLink
          href='https://www.linkedin.com/company/ghostmotion'
          target='_blank'
          rel='noreferrer'
        >
          <LinkedInIcon style={{ width: 30 }} />
        </StyledLink>
      </Socials>
      <Copyright>
        COPYRIGHT - ALL MATERIAL ON THIS SITE IS OWNED BY GHOST MOTION AND ITS
        PARTNERS.
        <br />
        THE MATERIAL CAN NOT BE USED OR REPRODUCED IN ANY WAY OR IN ANY MEDIA
        WITHOUT AN AGREEMENT IN
        <br />
        WRITING FROM GHOST MOTION © COPYRIGHT. ALL RIGHTS RESERVED.
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
