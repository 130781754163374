import React, { useState } from "react"
import styled from "styled-components"
// @ts-ignore
import Masonry from "react-responsive-masonry"
import { PhotoCollage as PhotoCollageType } from "../../../types"
import ImageViewer from "react-simple-image-viewer"
import isMobile from "is-mobile"

const Container = styled.div`
  margin: 50px 0;
`

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
`

export interface PhotoCollageProps {
  component: PhotoCollageType
}

const PhotoCollage: React.FC<PhotoCollageProps> = ({ component }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const closeImageViewer = (): void => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const openImage = (index: number): void => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const columnsCount = component.photos.length === 1 ? 1 : isMobile() ? 2 : 3

  return (
    <Container>
      <Masonry columnsCount={columnsCount} gutter='20px'>
        {component.photos.map((image, i) => (
          <Image
            style={{ cursor: "pointer" }}
            key={image}
            src={image}
            onClick={() => openImage(i)}
          />
        ))}
      </Masonry>

      {isViewerOpen && (
        <ImageViewer
          src={component.photos}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </Container>
  )
}

export default PhotoCollage
