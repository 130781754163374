import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

export interface ProjectGraphProps {
  inData: Array<{ year: number, count: number }>
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
)
export const options = {
  responsive: true,
  plugins: {
    // title color
    title: {
      display: true,
      text: 'Projects by Year',
      color: '#fff'
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#fff'

      }
    },
    y: {
      ticks: {
        color: '#fff'
      }
    }
  }

}

const ProjectGraph: React.FC<ProjectGraphProps> = ({ inData }) => {
  const labels = inData.map(d => d.year)
  const data = {
    labels,
    datasets: [
      {
        label: 'Projects',
        data: inData.map(d => d.count),
        backgroundColor: 'rgba(0, 212, 255, 0.5)'
      }
    ]
  }
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  )
}

export default ProjectGraph
