import React from "react"
import { Text as TextType } from "../../../types"
import { updatePostComponent } from "../../utils/firebase"
import Card from "../Card"
import TextArea from "../TextArea"
import { ComponentWrapper } from "./index"

export interface TextProps {
  component: TextType
  postID: string
}

const Text: React.FC<TextProps> = ({ component, postID }) => {
  return (
    <ComponentWrapper component={component} postID={postID} title='Text'>
      <Card>
        <TextArea
          value={component.content}
          onChange={async (text) =>
            await updatePostComponent(postID, component.id, { content: text })
          }
          placeholder='Write text here...'
        />
      </Card>
    </ComponentWrapper>
  )
}

export default Text
