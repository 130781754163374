import React, { useRef } from 'react'
import styled from 'styled-components'
import Colors from '../utils/colors.json'

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 5;
`

const InputElement = styled.input`
  border-radius: 8px;
  border-width: 0;
  background: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  flex: 1;
  outline-style: none;
  cursor: pointer;
  transition: 200ms;
  &:hover, &:focus {
    background-color: ${Colors.GM_Grey};
  }
`

export interface InputProps {
  value: string
  onChange: (text: string) => void
  onBlur?: (text: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  style?: React.CSSProperties
  placeholder?: string
  type?: string
  children?: any
}

const Input: React.FC<InputProps> = ({ children, style, value, onChange, onBlur, placeholder, type, onKeyDown }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Container>
      <InputElement
        ref={inputRef}
        style={style}
        value={value}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onBlur={(e) => onBlur?.(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </Container>
  )
}

export default Input
