import React, { useEffect, useState } from "react"
import ImageViewer from "react-simple-image-viewer"
import styled from "styled-components"
// @ts-ignore
import Masonry from "react-responsive-masonry"
import Footer from "../components/Footer"
import Loading from "../components/Loading"
import VimeoVideo from "../components/VimeoVideo"
import GhostMotionInAction from "../img/GhostMotion-in-action.jpg"
import useGetWeddingImages from "../utils/useGetWeddingImages"

const Container = styled.div`
  padding: 60px 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  background-color: white;
`
const Image = styled.img`
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
`
const BottomContainer = styled.div`
  padding-top: 96px;
  display: flex;
  gap: 48px;
  width: 80vw;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    width: 90vw;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`
const BottomImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: 2px;
  flex: 1;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  max-width: 500px;
  min-width: 300px;
  flex: 1;
`
const Title = styled.div`
  color: #000;
  font-size: 4rem;
  width: 75%;
  max-width: 400px;
  font-family: "Abril Fatface";

  @media only screen and (max-width: 600px) {
    max-width: 500px;
  }
`
const VideoContainer = styled.div`
  width: 80%;
  max-width: 1000px;
  @media only screen and (max-width: 1060px) {
    width: 100%;
  }
`
const Description = styled.span`
  width: 75%;
  max-width: 400px;
  font-size: 16px;
  color: #000;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
const MailLink = styled.a`
  color: #000;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: #450a0a;
  }
`

const Love: React.FC = () => {
  const images = useGetWeddingImages()
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [columnsCount, setColumnsCount] = useState(3)

  const closeImageViewer = (): void => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const openImage = (index: number): void => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  useEffect(() => {
    setColumnsCount(
      window.innerWidth < 600 ? 2 : window.innerWidth < 1060 ? 3 : 4
    )
    window.addEventListener("resize", () => {
      setColumnsCount(
        window.innerWidth < 600 ? 2 : window.innerWidth < 1060 ? 3 : 4
      )
    })
  }, [columnsCount])

  return (
    <Container>
      {images.length === 0 && <Loading style={{ color: "#000" }} />}
      <Masonry gutter='20px' columnsCount={columnsCount}>
        {images.map((image, i) => (
          <Image
            key={i}
            src={image}
            style={{ width: "100%" }}
            alt='randomimage'
            onClick={() => openImage(i)}
          />
        ))}
      </Masonry>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <VideoContainer>
          <VimeoVideo
            src='https://player.vimeo.com/video/899967467?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            title='Fanny &amp; Jonas 2023-08-12'
          />
        </VideoContainer>
        <BottomContainer>
          <BottomImage src={GhostMotionInAction} alt='ghostmotion' />
          <TextContainer>
            <Title>Love</Title>
            <Description>
              Att få förtroendet att finnas med på er dag är stort, vi ser fram
              emot att dokumentera just er historia.
              <br />
              <br />
              Tveka inte att höra av er med frågor eller tankar. För att skicka
              en förfrågan om ert datum, maila oss på{" "}
              <MailLink href='mailto:hello@ghostmotion.se'>
                hello@ghostmotion.se
              </MailLink>
            </Description>
          </TextContainer>
        </BottomContainer>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
      <Footer />
    </Container>
  )
}

export default Love
