import React from 'react'
import { Text as TextType } from '../../../types'
import { Text as TextComponent } from '../Titles'

export interface TextProps {
  component: TextType
}

const Text: React.FC<TextProps> = ({ component }) => {
  return (
    <TextComponent>
      {component.content}
    </TextComponent>
  )
}

export default Text
