import React, { CSSProperties } from "react"

const InstagramIcon: React.FC<{ style?: CSSProperties }> = ({ style }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' style={style}>
    <path
      d='M296.96 0h430.08C890.88 0 1024 133.12 1024 296.96v430.08C1024 891.05 891.05 1024 727.04 1024H296.96C133.12 1024 0 890.88 0 727.04V296.96C0 132.95 132.95 0 296.96 0m-10.24 102.4c-101.8 0-184.32 82.52-184.32 184.32v450.56c0 101.89 82.43 184.32 184.32 184.32h450.56c101.8 0 184.32-82.52 184.32-184.32V286.72c0-101.89-82.43-184.32-184.32-184.32H286.72m494.08 76.8c35.35 0 64 28.65 64 64s-28.65 64-64 64-64-28.65-64-64 28.65-64 64-64M512 256c141.38 0 256 114.62 256 256S653.38 768 512 768 256 653.38 256 512s114.62-256 256-256m0 102.4c-84.83 0-153.6 68.77-153.6 153.6S427.17 665.6 512 665.6 665.6 596.83 665.6 512 596.83 358.4 512 358.4Z'
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default InstagramIcon
