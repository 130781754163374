import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Videoplayer } from '../../../types'

const Iframe = styled.iframe<{ height: number }>`
  border-width: 0;
  width: 100%;
  height: ${(props: { height: number }) => props.height}px;
  border-radius: 15px;
`
const VideoContainer = styled.div`
  border-width: 0;
  width: 100%;
  display: flex; 
  justify-content: center;
`

export interface VideoProps {
  component: Videoplayer
}

const Video: React.FC<VideoProps> = ({ component }) => {
  const videoRef = useRef<HTMLIFrameElement>(null)
  const [height, setHeight] = useState(0)
  let autoplayString = '?autoplay=0'
  if (component.autoplay !== undefined) {
    autoplayString = component.autoplay ? '?autoplay=1' : '?autoplay=0'
  }

  const updateHeight = (): void => {
    if (videoRef.current != null) {
      setHeight(videoRef.current.offsetWidth * 9 / 16)
    }
  }

  useEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
  }, [])

  return (
    <VideoContainer>
      {component.url !== '' && (
        <Iframe
          ref={videoRef}
          src={component.url + autoplayString + '&loop=1&autopause=0&muted=1'}
          height={height}
          allow='autoplay; fullscreen; picture-in-picture'
        />
      )}
    </VideoContainer>
  )
}

export default Video
