import { getApp } from "firebase/app"
import { collection, doc, getFirestore } from "firebase/firestore"
import React, { useEffect, useMemo, useState } from "react"
import { useCollection, useDocument } from "react-firebase-hooks/firestore"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { headerHeight } from "../components/Header"
import Title, { SubTitle } from "../components/Titles"
import Colors from "../utils/colors.json"
import {
  createEmptyPostComponent,
  deletePost,
  updatePost,
  updatePostStats,
} from "../utils/firebase"
import {
  AlignLeftOutlined,
  CalendarOutlined,
  CheckOutlined,
  PlusOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons"
import firebase from "firebase/compat/app"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import Popup from "react-animated-popup"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// @ts-ignore
import Switch from "react-ios-switch"
import { Component, Post } from "../../types"
import BreadCrumb from "../components/BreadCrumb"
import Button from "../components/Button"
import ColorPicker from "../components/ColorPicker"
import DraggableList from "../components/DraggableList"
import Input from "../components/Input"
import Loading from "../components/Loading"
import PhotoPicker from "../components/PhotoPicker"
import Tags from "../components/Tags"
import TextArea from "../components/TextArea"
import EditPostComponent from "../components/postComponentsAdmin"

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: calc(20px + ${headerHeight}px);
  width: 80vw;
  max-width: 80vw;
  overflow-x: hidden;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 10px;
`
const Description = styled.div<{ color: string }>`
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  color: ${(props: { color: string }) => props.color};
`
const Properties = styled.div`
  display: flex;
  flex: 1;
`

const Expander = styled.div`
  display: flex;
  flex: 5;
`
const Divider = styled.div`
  min-height: 1px;
  width: 100%;
  background-color: ${Colors.GM_Grey_Light};
  margin: 20px 0;
`

const Spacer = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
`

const AdminPost: React.FC = () => {
  const { id } = useParams()

  const [firebasePost, loadingPost] = useDocument(
    doc(getFirestore(getApp()), "posts/" + String(id))
  )
  const post = firebasePost?.data() as Post

  const componentOrder = useMemo(() => post?.componentOrder, [post])

  const [reorderedComponents, setReorderedComponents] = useState<Component[]>(
    []
  )

  const [firebaseComponents] = useCollection(
    collection(doc(getFirestore(getApp()), "posts/" + String(id)), "components")
  )
  const components = firebaseComponents?.docs.map((doc) => doc.data()) as
    | Component[]
    | undefined

  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false)

  // If the order on the database is updated just update to it
  useEffect(() => {
    if (reorderedComponents.length > 0) {
      setReorderedComponents([])
    }
  }, [componentOrder, reorderedComponents.length])

  // Update the database order if order has been edited
  useEffect(() => {
    if (reorderedComponents.length > 0) {
      updatePost(post.id, {
        componentOrder: reorderedComponents.map((c) => c.id),
      }).catch((e) => alert(e))
    }
  }, [post?.id, reorderedComponents])

  const updateDate = (date: Date): void => {
    updatePostStats(post.id, { end: date.toISOString() }).catch((e) => alert(e))
  }

  if (loadingPost) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if (post == null) {
    return (
      <Container>
        <h1>Not found</h1>
      </Container>
    )
  }

  const uploadPhoto = async (photo: File): Promise<void> => {
    const storageRef = firebase
      .storage()
      .ref("images")
      .child(post.id + ".jpg")
    try {
      const snapshot = await uploadBytes(storageRef, photo)
      if (snapshot != null) {
        const url = await getDownloadURL(
          ref(getStorage(), snapshot.ref.fullPath)
        )
        await updatePost(post.id, { thumbnail: url })
      } else {
        throw new Error("Error uploading file.")
      }
    } catch (e) {
      alert(e)
    }
  }

  // Order posts by the order defined i the database
  let orderedData = components?.sort(
    (a, b) =>
      post.componentOrder.indexOf(a.id) - post.componentOrder.indexOf(b.id)
  )
  if (orderedData == null) {
    orderedData = []
  }

  const createComponent = async (type: string): Promise<void> => {
    setShowPopup(false)
    await createEmptyPostComponent(post.id, type)
  }

  return (
    <Container>
      <Popup
        style={{ backgroundColor: Colors.GM_Black1 }}
        visible={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <Title style={{ margin: "0 0 20px 0" }}>Select component type</Title>
        <Button
          onClick={async () => await createComponent("videoplayer")}
          iconType={PlusOutlined}
          color={Colors.GM_Orange}
        >
          Videoplayer
        </Button>
        <Button
          onClick={async () => await createComponent("photoCollage")}
          iconType={PlusOutlined}
          color={Colors.GM_Orange}
        >
          Photo Collage
        </Button>
        <Button
          onClick={async () => await createComponent("photo360")}
          iconType={PlusOutlined}
          color={Colors.GM_Orange}
        >
          Photo 360
        </Button>
        <Button
          onClick={async () => await createComponent("text")}
          iconType={PlusOutlined}
          color={Colors.GM_Orange}
        >
          Text
        </Button>
      </Popup>
      <Column>
        <Row>
          <BreadCrumb
            crumbs={[
              { url: "/admin", title: "admin" },
              { url: "/admin/post/" + post.id, title: "edit " + post.title },
            ]}
          />
          <Expander />
          <SubTitle style={{ paddingRight: 10, margin: 0 }}>Published</SubTitle>
          <Switch
            checked={post.published}
            onChange={async (value: boolean) =>
              await updatePost(post.id, { published: value })
            }
          />
        </Row>
        <PhotoPicker
          photo={post.thumbnail}
          uploadFunction={uploadPhoto}
          style={{ minWidth: "80vw", minHeight: "30vw" }}
        />

        {/* onClick -> focus input */}
        <div
          onClick={() => {
            const input = document.getElementById("title-input")
            if (input != null) {
              input.focus()
            }
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: Colors.GM_Black1,
            padding: "10px 20px",
            borderRadius: "8px",
            margin: "20px 0",
          }}
        >
          <Input
            value={post.title}
            style={{ fontSize: 30 }}
            onChange={async (text) =>
              await updatePost(post.id, { title: text })
            }
            key='title-input'
          />
          <CheckOutlined
            style={{
              padding: "10px",
              backgroundColor: "#555555",
              borderRadius: "8px",
              color: Colors.GM_Orange,
            }}
            size={30}
          />
        </div>

        {/* <EditIconRow><TitleInput value={post.title} onChange={async (e) => await updatePost(post.id, { title: e.target.value })} /></EditIconRow> */}

        <Row>
          <Properties>
            <CalendarOutlined style={{ color: Colors.GM_Grey_Light }} />
            <Description color={Colors.GM_Grey_Light}> Date </Description>
          </Properties>
          <Expander>
            {post?.stats?.end != null && (
              <DatePicker
                selected={new Date(post.stats.end)}
                onChange={(date) => date != null && updateDate(date)}
              >
                {" "}
              </DatePicker>
            )}
          </Expander>
        </Row>
        <Row>
          <Properties>
            <AlignLeftOutlined
              style={{ color: Colors.GM_Grey_Light, transform: "scaleY(-1)" }}
            />
            <Description color={Colors.GM_Grey_Light}>
              {" "}
              Description{" "}
            </Description>
          </Properties>
          <TextArea
            placeholder='Empty'
            value={post.description}
            onChange={async (text) =>
              await updatePost(post.id, { description: text })
            }
          />
          {/* icon checkmark */}
        </Row>
        <Row>
          <Properties>
            <UserOutlined style={{ color: Colors.GM_Grey_Light }} />
            <Description color={Colors.GM_Grey_Light}>
              {" "}
              Client name{" "}
            </Description>
          </Properties>
          <Input
            placeholder='Empty'
            value={post.stats.clientName}
            onChange={async (text) =>
              await updatePost(post.id, {
                stats: { ...post.stats, clientName: text },
              })
            }
          />
        </Row>
        <Row>
          <Properties>
            <UserOutlined style={{ color: Colors.GM_Grey_Light }} />
            <Description color={Colors.GM_Grey_Light}>
              {" "}
              Client Link{" "}
            </Description>
          </Properties>
          <Input
            placeholder='Empty'
            value={post.stats.clientLink}
            onChange={async (text) =>
              await updatePost(post.id, {
                stats: { ...post.stats, clientLink: text },
              })
            }
          />
        </Row>
        <Row>
          <Properties>
            <TagOutlined style={{ color: Colors.GM_Grey_Light }} />
            <Description color={Colors.GM_Grey_Light}> Tags </Description>
          </Properties>
          <Tags post={post} />
        </Row>
        <Row>
          <Properties>
            <TagOutlined style={{ color: Colors.GM_Grey_Light }} />
            <Description color={Colors.GM_Grey_Light}> Color </Description>
          </Properties>
          <ColorPicker
            hexValue={post?.titleColor}
            onChange={async (text) =>
              await updatePost(post.id, { titleColor: text.hex })
            }
          />
        </Row>

        <Divider />

        <Button onClick={() => navigate("/post/" + post.id)}>
          Preview post
        </Button>

        <Button
          type='warning'
          onClick={async () =>
            await deletePost(post.id).then(() => navigate("/admin"))
          }
        >
          Delete post
        </Button>
        <Spacer />

        <Title style={{ marginTop: headerHeight + 8 }}>Components</Title>
        {components != null && (
          <DraggableList
            lockX
            onChange={(data) => setReorderedComponents(data as Component[])}
            itemStyle={{ paddingRight: 20 }}
            data={
              reorderedComponents.length > 0 ? reorderedComponents : orderedData
            }
            keyExtractor={(data: Component) => data.id}
            renderItem={(c: Component) => {
              return (
                <EditPostComponent component={c} key={c.id} postID={post.id} />
              )
            }}
          />
        )}
        <Spacer />
        <Button
          onClick={() => setShowPopup(true)}
          color={Colors.GM_Orange}
          iconType={PlusOutlined}
          style={{
            flexDirection: "row-reverse",
            margin: "30px 60px",
            borderRadius: "100px",
            fontSize: "26px",
            padding: "20px 37px",
            width: "270px",
            alignItems: "baseline",
            alignSelf: "center",
          }}
        >
          Add component
        </Button>
        <Spacer />
      </Column>
    </Container>
  )
}

export default AdminPost
