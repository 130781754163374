import React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  color: string
  darkerColor: string
}

const Container = styled.div<ContainerProps>`
    display: flex;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.1); 
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.1);
    padding: 20px;
    flex-direction: column;
    max-width: 300px;
    background-color: ${(props: ContainerProps) => props.color};
    color: ${(props: ContainerProps) => props.darkerColor};
    border-color: ${(props: ContainerProps) => props.darkerColor};
    border-width: 1px;
    border-style: solid;
    margin: 5px 0;
    animation: popup 0.3s;

    @keyframes popup {
      0%{
        transform: scale(1);
      }
      50%{
        transform: scale(1.4);
      }
      60%{
        transform: scale(1.1);
      }
      70%{
        transform: scale(1.2);
      }
      80%{
        transform: scale(1);
      }
      90%{
        transform: scale(1.1);
      }
      100%{
        transform: scale(1);
      }
    }
    
`

export interface AlertProps {
  type?: 'warning' | 'status'
  children: any
}

const Alert: React.FC<AlertProps> = ({ type = 'status', children }) => {
  const color = type === 'status' ? '#d9ffe2' : '#ffdad9'
  const darkerColor = type === 'status' ? '#4b965d' : '#e37774'

  if (children == null) {
    return null
  }

  return (
    <Container color={color} darkerColor={darkerColor}>
      {children}
    </Container>
  )
}

export default Alert
