import React from 'react'
import styled from 'styled-components'
import { UserRecord } from '../utils/firebase'
import Loading from './Loading'

const Table = styled.table`
  padding: 20px 0;
`

const THead = styled.thead`
  th {
    font-weight: bold;
  }
`

const Row = styled.tr`
  flex-direction: row;
`

const Item = styled.th`
  text-align: left;
  padding-right: 20px;
  font-weight: normal;
`

export interface UserProps {
  users: UserRecord[]
}

const UserTable: React.FC<UserProps> = ({ users }) => {
  return (
    <Table>
      <THead>
        <Row>
          <Item>Email</Item>
          <Item>Role</Item>
        </Row>
      </THead>
      <tbody>
        {users.map(user => {
          return (
            <Row key={user.id}>
              <Item>{user.email}</Item>
              <Loading style={{ width: '10px' }} />
            </Row>
          )
        })}
      </tbody>
    </Table>
  )
}

export default UserTable
