import 'firebase/firestore'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { Component, Post } from '../../types'
import { v4 as uuid } from 'uuid'

export interface UserRecord {
  id: string
  email: string
}

// Profile
export async function updateProfile (userRecord: UserRecord): Promise<void> {
  const db = firebase.firestore()
  await db.collection('users').doc(userRecord.id).set(userRecord)
}

// Posts
export async function updatePostOrder (order: string[]): Promise<void> {
  const db = firebase.firestore()
  await db.collection('sorting').doc('posts').set({ order })
}

export async function createPost (post: Post): Promise<void> {
  const db = firebase.firestore()
  await db.collection('posts').doc(post.id).set(post)

  // update post order

  const sorting = await db.collection('sorting').doc('posts').get()
  const order = sorting.data()?.order
  order.push(post.id)
  await db.collection('sorting').doc('posts').set({ order })
}

type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
}

export async function updatePost (id: string, post: Subset<Post>): Promise<void> {
  const db = firebase.firestore()
  await db.collection('posts').doc(id).update(post)
}

export async function deletePost (id: string): Promise<void> {
  if (window.confirm('Are you sure you want to permanently delete this post?')) {
    const db = firebase.firestore()
    await db.collection('posts').doc(id).delete()
  } else {
    throw (new Error('User cancelled'))
  }
}

// Post components

export async function createEmptyPostComponent (postID: string, type: string): Promise<void> {
  switch (type) {
    case 'videoplayer':
      await createPostComponent(postID, {
        id: uuid(),
        type: 'videoplayer',
        url: '',
        width: 0,
        height: 0,
        autoplay: false
      })
      break
    case 'photoCollage':
      await createPostComponent(postID, {
        id: uuid(),
        type: 'photoCollage',
        photos: []
      })
      break
    case 'photo360':
      await createPostComponent(postID, {
        id: uuid(),
        type: 'photo360',
        url: ''
      })
      break
    case 'text':
      await createPostComponent(postID, {
        id: uuid(),
        type: 'text',
        content: ''
      })
      break
  }
}

export async function createPostComponent (postID: string, component: Component): Promise<void> {
  const db = firebase.firestore()
  await db.collection('posts').doc(postID).collection('components').doc(component.id).set(component)
  const componentOrder = await (await db.collection('posts').doc(postID).get()).data()?.componentOrder
  if (Array.isArray(componentOrder)) {
    await updatePost(postID, { componentOrder: [...componentOrder, component.id] })
  }
}

export async function updatePostComponent (postID: string, componentID: string, component: Partial<Component>): Promise<void> {
  const db = firebase.firestore()
  await db.collection('posts').doc(postID).collection('components').doc(componentID).update(component)
}

export async function deletePostComponent (postID: string, component: Component): Promise<void> {
  if (window.confirm('Are your sure you want to permanently delete this component?')) {
    // Delete all linked assets
    if (component.type === 'photoCollage') {
      if (component.photos.length > 0) {
        for await (const photo of component.photos) {
          const storageRef = await firebase.storage().refFromURL(photo)
          await storageRef.delete()
        }
      }
    }

    const db = firebase.firestore()
    await db.collection('posts').doc(postID).collection('components').doc(component.id).delete()
    const componentOrder = await (await db.collection('posts').doc(postID).get()).data()?.componentOrder
    if (Array.isArray(componentOrder)) {
      await updatePost(postID, { componentOrder: componentOrder.filter(c => c !== component.id) })
    }
  } else {
    throw (new Error('User cancelled'))
  }
}

export async function updatePostStats (postID: string, stats: Partial<Post['stats']>): Promise<void> {
  const db = firebase.firestore()
  const oldStats = await (await db.collection('posts').doc(postID).get()).data()?.stats
  await db.collection('posts').doc(postID).update({ stats: { ...oldStats, ...stats } })
}
