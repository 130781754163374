import React from "react"
import styled from "styled-components"

interface VimeoVideoProps {
  src: string
  title: string
  allow?: string
}

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56.25% 0 0 0;
  position: relative;
`
const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VimeoVideo: React.FC<VimeoVideoProps> = ({ src, title, allow }) => {
  return (
    <VideoContainer>
      <ResponsiveIframe
        src={src}
        frameBorder='0'
        allow={allow || "autoplay; fullscreen; picture-in-picture"}
        title={title}
      />
    </VideoContainer>
  )
}

export default VimeoVideo
