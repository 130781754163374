import React, { useState } from 'react'
import styled from 'styled-components'
import { ColorChangeHandler, ChromePicker } from 'react-color'

export interface ColorPickerProps {
  onChange?: ColorChangeHandler | null
  hexValue?: string
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 5;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 150px;
  margin-right: 10px;
  background-color: ${props => props.color};
  border-radius: 4px;
  font-weight: bold;
`

const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps) => {
  const [color, setColor] = useState(props.hexValue)

  return (
    <StyledContainer>
      <Box color={color}>{color}</Box>
      <ChromePicker
        color={color}
        onChange={(color) => setColor(color.hex)}
        onChangeComplete={
          props.onChange != null
            ? props.onChange
            : (color) => console.log(color.hex)
        }
      />
    </StyledContainer>
  )
}

export default ColorPicker
