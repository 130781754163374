import React from "react"
import styled from "styled-components"
import Colors from "../utils/colors.json"

export interface HeaderButtonProps {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}

const ButtonContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 22px;
  flex-direction: column;
  text-transform: uppercase;
  padding: 15px;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  transition: 100ms;
  color: ${Colors.GM_Solid_White};

  &:hover {
    color: ${Colors.GM_Solid_White};
  }
  .underline {
    border-radius: 0.05em;
    transition: 200ms ease-in-out;
    width: 100%;
    height: 0em;
    background-color: ${(props: { active: boolean }) =>
      props.active ? Colors.GM_Solid_White : "transparent"};
    height: ${(props: { active: boolean }) =>
      props.active ? "0.15em" : "0em"};
    opacity: ${(props: { active: boolean }) => (props.active ? "1" : "0")};
  }
  &:hover .underline {
    opacity: 1;
    background-color: ${Colors.GM_Solid_White};
    height: 0.15em;
  }
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const HeaderButton: React.FC<HeaderButtonProps> = ({
  onClick,
  active,
  children,
}) => {
  return (
    <Wrapper>
      <ButtonContainer
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        active={active}
      >
        {children}
        <div className='underline' />
      </ButtonContainer>
    </Wrapper>
  )
}

export default HeaderButton
