import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Title from './Titles'
import { RightOutlined } from '@ant-design/icons'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 40px;
`

const Pair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export interface Crumb {
  url: string
  title: string
}

export interface BreadCrumbProps {
  crumbs: Crumb[]
  style?: React.CSSProperties
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ crumbs, style }) => {
  const navigate = useNavigate()

  const handleClick = (url: string): void => {
    navigate(url)
  }

  return (
    <Container style={style}>
      {crumbs.map((crumb, i) => {
        return (
          <Pair key={crumb.title}>
            <Title style={{ margin: 0 }} onClick={() => handleClick(crumb.url)}>{crumb.title}</Title>
            {i !== crumbs.length - 1 && <RightOutlined />}
          </Pair>
        )
      })}
    </Container>
  )
}

export default BreadCrumb
