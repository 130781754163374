import React from 'react'
import styled from 'styled-components'
import Colors from '../utils/colors.json'

const Description = styled.textarea`
  width: 100%;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bold;
  flex: 5;
  cursor: pointer;
  height: 10px;
  height: 100%;
  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
      color: none;
      background: none;
  }
  outline-style: none;
  :hover {
    background-color: ${Colors.GM_Grey};
  }
  focus {
    background-color: ${Colors.GM_Solid_Black};
  }
`

export interface TextAreaProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  children?: any
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange, placeholder }) => {
  const autoGrow = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    e.currentTarget.style.minHeight = '10px'
    e.currentTarget.style.height = String(e.currentTarget.scrollHeight) + 'px'
  }
  return (
    <Description value={value} onInput={autoGrow} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />

  )
}

export default TextArea
