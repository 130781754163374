import React, { useRef } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  scroll-snap-align: center;
  z-index: 400;
`
const strength = 10

const calc = (x: number, y: number): number[] => [-y * strength, x * strength, 1.08]
const trans = (x: number, y: number, s: number): string => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

export interface CardTiltProps {
  children: any
}

const CardTilt: React.FC<CardTiltProps> = ({ children }) => {
  const [props, set] = useSpring<{ xys: number[] }>(() => (
    {
      xys: [0, 0, 1],
      config: { mass: 5, tension: 1000, friction: 80 }
    }))

  const mouseLeave = (): void => {
    set({ xys: [0, 0, 1] })
  }
  const myRef: any = useRef(null)

  const mouseMove = (e: any): void => {
    const rect = myRef.current.getBoundingClientRect()
    const x = (e.clientX - rect.x) / rect.width
    const y = (e.clientY - rect.y) / rect.height
    set({ xys: calc(x - 0.5, y - 0.5) })
  }

  return (
    <Card>
      <animated.div
        ref={myRef}
        className='card'
        onMouseMove={mouseMove}
        onMouseLeave={mouseLeave}
        style={{ transform: props.xys.to(trans), borderRadius: 20, overflow: 'hidden' }}
      >
        {children}
      </animated.div>

    </Card>
  )
}

export default CardTilt
