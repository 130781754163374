import * as React from 'react'
import { CSSProperties } from 'react'

const VimeoIcon: React.FC<{style?: CSSProperties}> = ({ style }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1024 1024'
    style={style}
  >

    <path
      d='M910.99 0H113.01C50.6 0 0 50.6 0 113.01v797.98C0 973.4 50.6 1024 113.01 1024h797.98c62.41 0 113.01-50.6 113.01-113.01V113.01C1024 50.6 973.4 0 910.99 0Zm28.92 312.57c-3.85 83.48-62.08 197.79-174.67 343.36-116.45 152.41-214.92 227.33-296.26 227.33-49.23 0-91.62-46.24-126.3-139.14-23.55-83.91-44.95-168.68-68.93-253.45-25.69-92.47-53.09-138.71-83.06-138.71-5.99 0-28.26 13.7-66.79 40.67L83.66 340.4c42.81-37.25 83.91-74.49 125.01-111.74 56.51-48.81 98.9-74.49 126.72-77.06 66.79-6.85 107.89 39.39 123.3 137 16.7 105.75 28.26 171.25 34.68 196.94 18.41 87.34 39.82 130.15 63.36 130.15 17.98 0 44.95-27.4 80.92-84.34 35.96-56.51 55.23-99.75 57.8-129.72 5.14-48.81-14.13-73.21-57.8-73.21-20.55 0-41.53 4.71-63.36 14.13 41.96-138.28 122.44-205.5 241.03-201.65 88.19 2.57 129.72 59.94 124.58 171.68Z'
      style={{
        fill: '#fff'
      }}
    />
  </svg>
)

export default VimeoIcon
