import React from 'react'
import styled from 'styled-components'
import Colors from '../utils/colors.json'

const Container = styled.div`
  background-color: ${Colors.GM_Black2};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  margin: 0;
  flex-direction: column;
`
export interface CardProps {
  style?: React.CSSProperties
  children: any
}

const Card: React.FC<CardProps> = ({ children, style }) => {
  return (
    <Container style={style}>
      {children}
    </Container>
  )
}

export default Card
