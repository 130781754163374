import React from 'react'
import { Component } from '../../../types'
import PhotoCollage from './PhotoCollage'
import Text from './Text'
import Video from './Video'

export interface PostComponentProps {
  component: Component
}

const PostComponent: React.FC<PostComponentProps> = ({ component }) => {
  switch (component.type) {
    case 'videoplayer':
      return <Video component={component} />
    case 'photoCollage':
      return <PhotoCollage component={component} />
    case 'text':
      return <Text component={component} />
    case 'photo360':
      return <p>photo360!</p>

    default:
      return <p>Error loading component</p>
  }
}

export default PostComponent
