import React from 'react'
import styled from 'styled-components'

const TitleElement = styled.div<{ center: boolean }>`
  text-transform: uppercase;
  text-align: ${(props: { center: boolean }) => props.center ? 'center' : 'left'};
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0 10px 0;
`

export interface TitleProps {
  style?: React.CSSProperties
  onClick?: () => void
  center?: boolean
  children: any
}

const Title: React.FC<TitleProps> = ({ children, style, onClick, center = false }) => {
  return (
    <TitleElement center={center} style={{ ...style, cursor: onClick !== undefined ? 'pointer' : 'auto' }} onClick={onClick}>
      {children}
    </TitleElement>
  )
}

const SubTitleElement = styled.div`
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 4px 0;
`

const SubTitle: React.FC<TitleProps> = ({ children, style, onClick }) => {
  return (
    <SubTitleElement style={{ ...style, cursor: onClick !== undefined ? 'pointer' : 'auto' }} onClick={onClick}>
      {children}
    </SubTitleElement>
  )
}

export interface TextProps {
  style?: React.CSSProperties
  children: any
}

const Description = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const Text: React.FC<TextProps> = ({ children, style }) => {
  return (
    <Description>
      {children}
    </Description>
  )
}

export default Title
export {
  SubTitle,
  Text
}
