import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
  DragEvent,
} from "react"
import styled from "styled-components"
import DraggableDots from "../img/dragDots.svg"

const Container = styled.div``

interface DraggableProps {
  position: Coordinate
  offset: number
  animate: boolean
  fillColor: string
}
const Dots = styled.img`
  width: 20px;
  height: 25px;
  padding: 2px 0 1px 0;
  border-radius: 2px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 10px;
  &:hover {
    background-color: #262626;
  }
`
const Draggable = styled.div<DraggableProps>`
  transform: translateX(${(props) => props.position.x}px)
    translateY(${(props) => props.position.y + props.offset}px);
  z-index: ${(props) => (props.position.y === 0 ? 1 : 100)};
  transition: ${(props) => (props.animate ? 200 : 0)}ms;
  position: relative;
  user-select: none;
  height: max-content;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: row-reverse;
  background-color: rgba(0, 0, 0, 0);
  > * {
    flex: 1;
  }
  &:hover ${Dots} {
    opacity: 1;
    transition: 200ms;
  }
`

const Handle = styled.div`
  cursor: grab;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0;
  &:active {
    cursor: grabbing;
  }
`

interface Coordinate {
  x: number
  y: number
}

// const touchCoordinatesFromEvent = (e: Event): Coordinate => {
//   const touchLocation = e.targetTouches[0]
//   return { x: touchLocation.clientX, y: touchLocation.clientY }
// }

const mouseCoordinatesFromEvent = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
): Coordinate => {
  return { x: e.clientX, y: e.clientY }
}

export interface DraggableListProps {
  renderItem: (data: any) => ReactElement<any, any>
  data: any[]
  keyExtractor: (data: any) => string
  onChange: (data: any[]) => void
  lockX?: boolean
  itemStyle?: React.CSSProperties
  children?: any
}

function arraymove(arr: any[], fromIndex: number, toIndex: number): any[] {
  const newArr = JSON.parse(JSON.stringify(arr))
  const element = newArr[fromIndex]
  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, element)
  return newArr
}

let initialPos: Coordinate | null = null

const DraggableList: React.FC<DraggableListProps> = ({
  children,
  renderItem,
  data,
  keyExtractor,
  lockX = true,
  onChange,
  itemStyle,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  // const [order, setOrder] = useState<string[]>()
  const [elementHeights, setElementHeights] = useState<number[]>([])
  const [animate, setAnimate] = useState(false)

  const [lastMoved, setLastMoved] = useState<string | null>(null)

  const [draggingPosition, setDraggingPosition] = useState<Coordinate>()
  const [dragging, setDragging] = useState<null | string>(null)
  const draggingIndex = useMemo(
    () =>
      data
        .map((obj) => keyExtractor(obj))
        .indexOf(dragging == null ? "" : dragging),
    [data, dragging, keyExtractor]
  )

  const [draggingNewPosIndex, setDraggingNewPosIndex] = useState<
    number | null
  >()

  const startDrag = (id: string, coordinate: Coordinate): void => {
    initialPos = coordinate
    setDragging(id)
  }

  const endDrag = (): void => {
    if (draggingNewPosIndex == null) return
    const newArray = arraymove(data, draggingIndex, draggingNewPosIndex)

    setLastMoved(dragging)
    initialPos = null

    let newPos = 0
    if (draggingIndex > draggingNewPosIndex) {
      for (let index = draggingNewPosIndex; index < draggingIndex; index++) {
        newPos += elementHeights[index]
      }
    } else {
      for (let index = draggingNewPosIndex; index > draggingIndex; index--) {
        newPos -= elementHeights[index]
      }
    }

    setDraggingPosition({ x: 0, y: -newPos })

    window.setTimeout(() => {
      initialPos = null
      setAnimate(false)
      setDragging(null)
      setLastMoved(null)
      setDraggingNewPosIndex(null)
      onChange(newArray)
      setDraggingPosition({ x: 0, y: 0 })
    }, 200)
  }

  const drag = (coordinate: Coordinate): void => {
    if (initialPos !== null) {
      // const index = data.map(obj => keyExtractor(obj)).indexOf(dragging == null ? '' : dragging)
      setDraggingPosition({
        x: lockX ? 0 : coordinate.x - initialPos.x,
        y: coordinate.y - initialPos.y,
      })

      if (draggingPosition?.y == null) return
      let newIndex = draggingIndex
      let movement = draggingPosition.y
      if (draggingPosition.y < 0) {
        while (newIndex > 0) {
          movement = movement + elementHeights[newIndex - 1]
          if (movement > 0) break
          newIndex--
        }
      } else {
        while (newIndex < data.length) {
          if (newIndex + 2 > elementHeights.length) break
          movement = movement - elementHeights[newIndex + 1]
          if (movement < 0) break
          newIndex++
        }
      }
      setDraggingNewPosIndex(newIndex)
    }
  }

  useEffect(() => {
    // measure
    if (containerRef?.current != null) {
      const children = containerRef.current.children
      const heights = Array.from(children).map((elm) => elm.scrollHeight)
      setElementHeights(heights)
    }
    setAnimate(true)
    setLastMoved(null)
    setDraggingNewPosIndex(null)
  }, [data])

  const calculateOffset = (elmIndex: number): number => {
    if (keyExtractor(data[elmIndex]) === lastMoved) {
      return 0
    }
    if (draggingNewPosIndex == null) return 0
    if (draggingIndex < elmIndex && draggingNewPosIndex + 1 > elmIndex)
      return -elementHeights[draggingIndex]
    if (draggingIndex > elmIndex && draggingNewPosIndex - 1 < elmIndex)
      return elementHeights[draggingIndex]
    return 0
  }

  return (
    <>
      <Container ref={containerRef}>
        {data.map((obj, i) => {
          return (
            <Draggable
              animate={
                lastMoved === keyExtractor(obj)
                  ? true
                  : animate && dragging !== obj.id
              }
              offset={calculateOffset(i)}
              position={
                dragging === keyExtractor(obj) && draggingPosition != null
                  ? draggingPosition
                  : { x: 0, y: 0 }
              }
              // onMouseDown={e => startDrag(keyExtractor(obj), mouseCoordinatesFromEvent(e))}
              onMouseMove={(e) => drag(mouseCoordinatesFromEvent(e))}
              onMouseUp={(e) => endDrag()}
              key={keyExtractor(obj)}
              style={itemStyle}
              fillColor='#262626'
            >
              {renderItem(obj)}
              <Handle
                onMouseDown={(e) =>
                  startDrag(keyExtractor(obj), mouseCoordinatesFromEvent(e))
                }
              >
                <Dots
                  src={DraggableDots}
                  onDragStart={(e: DragEvent<HTMLDivElement>) => {
                    e.preventDefault()
                  }}
                />
              </Handle>
            </Draggable>
          )
        })}
      </Container>
    </>
  )
}

export default DraggableList
