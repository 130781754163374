import { useEffect, useState } from 'react';
import firebase from "firebase/compat/app"

const useGetWeddingImages = () => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const imagesRef = storageRef.child('wedding');

        const res = await imagesRef.listAll();
        const urls: string[] = [];

        await Promise.all(res.items.map(async (itemRef) => {
          const url = await itemRef.getDownloadURL();
          urls.push(url);
        }));

        setImages(urls);
      } catch (error) {
        console.error('Error fetching wedding images:', error);
      }
    };

    fetchImages();

    return () => {
      // cleanup
    };
  }, []);

  return images;
};

export default useGetWeddingImages;
