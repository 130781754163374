import React from 'react'
import styled from 'styled-components'
import Image from '../img/no-video.png'

const Container = styled.img`
  width: 100%;
  border-radius: 10px;
`

const PlaceHolder: React.FC = () => {
  return (
    <Container src={Image} />
  )
}

export default PlaceHolder
