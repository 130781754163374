import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { ComponentWrapper } from "."
import { Videoplayer as VideoplayerType } from "../../../types"
import { updatePostComponent } from "../../utils/firebase"
import Card from "../Card"
import Input from "../Input"
import PlaceHolder from "../Placeholder"
import Colors from "../../utils/colors.json"
// @ts-ignore
import Switch from "react-ios-switch"

export interface VideoplayerProps {
  postID: string
  component: VideoplayerType
  autoplay?: boolean
}

const Iframe = styled.iframe`
  border-width: 0;
  border-radius: 10px;
  overflow: hidden;
`
const InputURLWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
`
const LinkText = styled.div`
  margin-right: 5px;
  height: 100%;
  font-weight: bold;
  justify-self: center;
  padding: 10px 0;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
`

const Videoplayer: React.FC<VideoplayerProps> = ({ postID, component }) => {
  const videoRef = useRef<HTMLIFrameElement>(null)
  const [height, setHeight] = useState(0)
  const [autoplayState, setAutoplayState] = useState(component.autoplay)

  const setAutoplay = async (autoplay: boolean): Promise<void> => {
    try {
      await updatePostComponent(postID, component.id, { autoplay })
      setAutoplayState(autoplay)
    } catch (e) {
      alert(e)
    }
  }
  const updateHeight = (): void => {
    if (videoRef.current != null) {
      setHeight((videoRef.current.offsetWidth * 9) / 16)
    }
  }

  useEffect(() => {
    updateHeight()
    window.addEventListener("resize", updateHeight)
  }, [])

  const updateURL = async (url: string): Promise<void> => {
    try {
      // convert
      // https://vimeo.com/535624922
      // to
      // https://player.vimeo.com/video/535624922
      let videoURL = url
      if (!url.includes("player.vimeo.com/video")) {
        videoURL = url.replace("vimeo.com/", "player.vimeo.com/video/")
      }
      await updatePostComponent(postID, component.id, { url: videoURL })
    } catch (e) {
      alert(e)
    }
  }

  return (
    <ComponentWrapper title='Video' postID={postID} component={component}>
      <Card
        style={{
          margin: 0,
          backgroundColor: "transparent",
          padding: 0,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Column style={{ flex: 1.3 }}>
          <Row>
            <LinkText>Autoplay:</LinkText>
            <Switch
              checked={autoplayState}
              onChange={setAutoplay}
              style={{ transform: "scale(0.8)" }}
            />
          </Row>

          <InputURLWrapper>
            <LinkText>Link:</LinkText>
            <Input
              onChange={updateURL}
              value={component.url}
              placeholder='https://player.vimeo.com/video/535624922'
              style={{
                background: "transparent",
                textDecoration: "underline",
                backgroundColor: Colors.GM_Black1,
              }}
            />
          </InputURLWrapper>
        </Column>
        <Column style={{ flex: 1, flexDirection: "row-reverse" }}>
          {component.url !== "" && (
            <Iframe
              onLoad={updateHeight}
              ref={videoRef}
              src={component.url}
              height={height}
            />
          )}
          {component.url === "" && <PlaceHolder />}
        </Column>
      </Card>
    </ComponentWrapper>
  )
}

export default Videoplayer
