import React, { useEffect, useState, Suspense } from "react"
import styled from "styled-components"
import Colors from "../utils/colors.json"
import ProjectThumbnail from "../components/ProjectThumbnail"
import { Post } from "../../types"
import CardTilt from "../components/CardTilt"
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore"
import { collection, getFirestore, doc } from "firebase/firestore"
import { getApp } from "firebase/app"
import InfiniteScroller from "../components/InfiniteScroller"
import Loading from "../components/Loading"
import isMobile from "is-mobile"
import Footer from "../components/Footer"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import Ghosts from "../components/Ghosts"
import ComponentIcon from "../components/icons/customIcons"
// import ButtonCta from '../components/ButtonCta'

const PageContainer = styled.div`
  position: static;
  overflow-x: hidden;
  height: 100%;
  width: 100vw;
  scroll-snap-type: both mandatory;
  overflow-y: hidden;
`
const HeroContainer = styled.div`
  position: absolute;
  height: calc(100svh);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  &:hover {
    cursor: url("./cursor.svg"), auto;
  }
`

const VideoElement = styled.video`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  // background-color: ${Colors.GM_Black2};
  object-fit: cover;
  // opacity: 0.5;
`

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  transform-style: preserve-3d;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Limiter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  max-width: 1400px;
  margin: 0 auto;
  // padding: 0 2em;
  @media only screen and (max-width: 1400px) {
    // padding: 0 2em;
  }
  @media only screen and (max-width: 900px) {
    // padding: 0 1em;
  }
  @media only screen and (max-width: 650px) {
    // padding: 0 0.5em;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const ProjectSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  perspective-origin: center;
`
const ProjectSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1400px;
  align-items: center;
  justify-content: center;
  perspective-origin: center;
`
const PostContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  margin-bottom: 100px;
  &:nth-child(odd) {
    flex-direction: row-reverse;
    & > div:first-child {
      transform: translateX(-250px);
    }
    & > div:last-child {
      justify-content: flex-start !important;
      transform: translateX(250px);
    }
  }
  &:nth-child(even) {
    flex-direction: row;
    & > div:first-child {
      transform: translateX(250px);
    }
    & > div:last-child {
      justify-content: flex-end !important;
      transform: translateX(-250px);
      & > div {
        text-align: right;
        align-items: flex-end;
      }
    }
  }
`
const SmallPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`

const ProjectTitletext = styled.div<{
  color1: string
  color2: string
  fontSize: number
}>`
  margin: 0;
  font-size: ${(props) => props.fontSize}px;
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
  pointer-events: none;
  background: ${(props: { color1: string }) => props.color1};
  background: radial-gradient(
    ellipse farthest-side at top right,
    ${(props: { color2: string }) => props.color2} 0%,
    ${(props: { color1: string }) => props.color1} 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 0.1em;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transform: translateZ(0px);
  z-index: 999999;
  pointer-events: none;
  width: inherit;
  padding-top: 4rem;
  padding: 0 2rem;
`

const TextLimiter = styled.div`
  display: flex;
  min-width: 800px;
  max-width: 800px;
  justify-content: flex-end;
  pointer-events: none;
  @media only screen and (max-width: 1150px) {
    min-width: 500px;
    max-width: 500px;
  }
  @media only screen and (max-width: 750px) {
    justify-content: flex-start;
    min-width: 400px;
    margin: 0 4em;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Year = styled.div`
  font-size: clamp(12px, 1vw, 16px);
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
`
const ArrowDown = styled.div<{ showcomponent: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 25px;
  opacity: ${(props) => (props.showcomponent ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`

const FadeIn = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${Colors.GM_Black1};
  animation: fade 4s normal forwards ease-in-out;
  z-index: 1;
  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const Home: React.FC = () => {
  useEffect(() => {
    document.title = "Ghost Motion"
    setInterval(() => {
      setShowComponent(true)
    }, 3000)
  }, [])
  const [firebasePosts] = useCollection(
    collection(getFirestore(getApp()), "posts")
  )
  const posts = firebasePosts?.docs
    .map((doc) => doc.data())
    .filter((p) => p.published) as Post[] | undefined
  const sectionRef = React.useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [firebasePostsOrder] = useDocumentData(
    doc(getFirestore(getApp()), "sorting", "posts")
  )
  useEffect(() => {
    const handleResize = (): void => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return (): void => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const postOrdering = firebasePostsOrder?.order as string[]
  const orderedPosts = postOrdering
    ?.map((id) => posts?.find((p) => p.id === id))
    .filter((p) => p != null) as Post[] | undefined
  const selectedCase = orderedPosts?.slice(0, 6)
  const [showComponent, setShowComponent] = useState<boolean>(false)
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  if (orderedPosts == null) {
    return <Loading /> // borde inte renderas för video elementet, bara för posts
  }
  const scrollToSection = (): void => {
    if (sectionRef.current !== null) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  function changeColor(color: string, amount: number): string {
    // #FFF not supportet rather use #FFFFFF
    const clamp = (val: any): Number => Math.min(Math.max(val, 0), 0xff)
    const fill = (str: string): string => ("00" + str).slice(-2)

    // convert to color string to number
    const num = parseInt(color?.substring(1), 16)
    const red = clamp((num >> 16) + amount)
    const green = clamp(((num >> 8) & 0x00ff) + amount)
    const blue = clamp((num & 0x0000ff) + amount)
    return (
      "#" +
      fill(red.toString(16)) +
      fill(green.toString(16)) +
      fill(blue.toString(16))
    )
  }

  return (
    <PageContainer>
      <HeroContainer onClick={() => setShowVideo(!showVideo)}>
        <ArrowDown showcomponent={showComponent}>
          <ComponentIcon
            onClick={scrollToSection}
            fillColor='#fff'
            size={42}
            style={{ marginBottom: "1rem", cursor: "pointer" }}
            component='ChevronDown'
          />
        </ArrowDown>
        <div
          style={{
            display: showVideo ? "block" : "none",
            textTransform: "uppercase",
            width: "100vw",
            height: "100vh",
            position: "relative",
          }}
        >
          <VideoElement
            src='GM covervideo_color.mp4'
            autoPlay
            muted
            loop
            playsInline
            onClick={() => setShowVideo(!showVideo)}
            style={{
              display: showVideo ? "block" : "none",
              filter: "brightness(0.75)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: 10,
            }}
          >
            <img
              src='GM_text.svg'
              alt='Ghost Motion'
              style={{ width: "70vw", height: "auto" }}
            />
          </div>
        </div>
      </HeroContainer>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Suspense fallback={null}>
          <FadeIn />
          <Ghosts />
        </Suspense>
      </div>
      <InfiniteScroller />

      <ProjectSection ref={sectionRef}>
        <ProjectSectionContainer>
          <CardWrapper>
            <Limiter>
              {screenWidth < 750
                ? selectedCase?.map((post, index) => (
                    <SmallPostContainer key={post.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {isMobile() ? (
                          <ProjectThumbnail
                            post={post}
                            onClick={() => navigate("/post/" + post.id)}
                            height={200}
                            width={330}
                          />
                        ) : (
                          <ProjectThumbnail
                            post={post}
                            onClick={() => navigate("/post/" + post.id)}
                            height={225}
                            width={350}
                          />
                        )}
                        <TextLimiter>
                          <Info>
                            <ProjectTitletext
                              color1={post.titleColor}
                              color2={changeColor(post.titleColor, 70)}
                              fontSize={32}
                            >
                              {post.title}
                            </ProjectTitletext>
                            <Details style={{ pointerEvents: "none" }}>
                              <Row>
                                <div>
                                  {selectedCase[index].stats.clientName}
                                </div>
                              </Row>
                              <Year>
                                {new Date(
                                  selectedCase[0].stats.end
                                ).getFullYear()}
                              </Year>
                            </Details>
                          </Info>
                        </TextLimiter>
                      </div>
                    </SmallPostContainer>
                  ))
                : selectedCase?.map((post) => (
                    <PostContainer key={post.id}>
                      <div style={{ display: "flex" }}>
                        <CardTilt>
                          <ProjectThumbnail
                            post={post}
                            onClick={() => navigate("/post/" + post.id)}
                            height={600}
                            width={800}
                          />
                        </CardTilt>
                      </div>
                      <TextLimiter>
                        <Info>
                          <ProjectTitletext
                            color1={post.titleColor}
                            color2={changeColor(post.titleColor, 70)}
                            fontSize={120}
                          >
                            {post.title}
                          </ProjectTitletext>
                          <Details style={{ pointerEvents: "none" }}>
                            <Row>
                              <div>{post.stats.clientName}</div>
                            </Row>
                            <Year>
                              {new Date(post.stats.end).getFullYear()}
                            </Year>
                          </Details>
                        </Info>
                      </TextLimiter>
                    </PostContainer>
                  ))}
            </Limiter>
          </CardWrapper>
        </ProjectSectionContainer>
      </ProjectSection>
      <ButtonContainer>
        <Button onClick={() => navigate("/case")} iconSize={20}>
          View more
        </Button>
        {/* <ButtonCta onClick={() => navigate('/case')}>View more</ButtonCta> */}
      </ButtonContainer>
      <Footer />
    </PageContainer>
  )
}

export default Home
