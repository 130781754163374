import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression'
import Loading from './Loading'
import colors from '../utils/colors.json'
import { PlusOutlined } from '@ant-design/icons'

const Banner = styled.div<{ uri: string }>`
  display: flex;
  height: 200px;
  width: 100%;
  border-radius: 10px;
  background-image: ${(props: { uri: string }) => `url(${props.uri})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.GM_Black1};
  transition: 0.2s;

  :hover {
    background-color: ${colors.GM_Black2};
  }
`

export interface PhotoPickerProps {
  photo?: string
  onPhoto?: (photo: File) => void
  uploadFunction?: (photo: File) => Promise<void>
  style?: React.CSSProperties
}

const PhotoPicker: React.FC<PhotoPickerProps> = ({ photo, onPhoto, uploadFunction, style }) => {
  const [pickedImage, setPickedImage] = useState<File | null>()
  const selectRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  const compressImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setLoading(true)
    const options = {
      // maxSizeMB: 0.2,
      maxWidthOrHeight: 1080,
      initialQuality: 0.4,
      useWebWorker: true,
      // fileType: 'image/jpeg',
      alwaysKeepResolution: true
    }
    if (event.target?.files == null || event.target?.files?.length === 0) return
    const imageFile = event.target.files[0]
    try {
      const compressedFile = await imageCompression(imageFile, options)
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
      setPickedImage(compressedFile)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    const handleCompressedImage = async (): Promise<void> => {
      if (pickedImage != null) {
        if (uploadFunction != null) {
          await uploadFunction(pickedImage)
          setPickedImage(null)
          setLoading(false)
        } else if (onPhoto != null) {
          onPhoto(pickedImage)
          setLoading(false)
        }
      }
    }

    handleCompressedImage().catch(e => {
      alert(e)
      setLoading(false)
    })
  }, [onPhoto, pickedImage, uploadFunction])

  const selectImage = (): void => {
    if (selectRef.current != null) {
      selectRef.current.click()
    }
  }

  let imageURL = (pickedImage != null) ? pickedImage.webkitRelativePath : photo
  if (imageURL == null || imageURL === '') {
    imageURL = ''
  }

  return (
    <>
      <input style={{ display: 'none' }} ref={selectRef} type='file' accept='image/*' onChange={async (e) => await compressImage(e)} />
      <Banner style={style} onClick={selectImage} uri={imageURL}>
        {loading && <Loading />}
        {(!loading && imageURL === '') && <PlusOutlined name='plus' size={24} style={{ margin: 10 }} color='#fff' />}
      </Banner>
    </>
  )
}

export default PhotoPicker
