import React from "react"
import styled from "styled-components"
import { PhotoCollage as PhotoCollageType } from "../../../types"
// @ts-ignore
import Masonry from "react-responsive-masonry"
import PhotoPicker from "../PhotoPicker"
import firebase from "firebase/compat/app"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { v4 as uuid } from "uuid"
import { updatePostComponent } from "../../utils/firebase"
import { ComponentWrapper } from "."
import { CloseOutlined } from "@ant-design/icons"

const Container = styled.div``

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
`

const Delete = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer;
`

export interface PhotoCollageProps {
  component: PhotoCollageType
  postID: string
}

const PhotoCollage: React.FC<PhotoCollageProps> = ({ component, postID }) => {
  const images = component.photos

  const uploadPhoto = async (photo: File): Promise<void> => {
    const storageRef = firebase
      .storage()
      .ref("images/" + postID)
      .child(uuid() + ".jpg")
    try {
      const snapshot = await uploadBytes(storageRef, photo)
      if (snapshot != null) {
        const url = await getDownloadURL(
          ref(getStorage(), snapshot.ref.fullPath)
        )
        await updatePostComponent(postID, component.id, {
          photos: [...component.photos, url],
        })
      } else {
        throw new Error("Error uploading file.")
      }
    } catch (e) {
      alert(e)
    }
  }

  const deletePhoto = async (url: string): Promise<void> => {
    try {
      const storageRef = firebase.storage().refFromURL(url)
      await storageRef.delete()
      await updatePostComponent(postID, component.id, {
        photos: component.photos.filter((p) => p !== url),
      })
    } catch (e) {
      alert(e)
    }
  }

  return (
    <ComponentWrapper
      title='Photo collage'
      component={component}
      postID={postID}
    >
      <Container>
        <Masonry columnsCount={3} gutter='20px'>
          {images.map((image) => (
            <ImageContainer key={image}>
              <Delete onClick={async () => await deletePhoto(image)}>
                <CloseOutlined size={30} />
              </Delete>
              <Image src={image} />
            </ImageContainer>
          ))}
          <PhotoPicker style={{ margin: 0 }} uploadFunction={uploadPhoto} />
        </Masonry>
      </Container>
    </ComponentWrapper>
  )
}

export default PhotoCollage
