import * as React from 'react'
import { CSSProperties } from 'react'

const LinkedInIcon: React.FC<{style?: CSSProperties}> = ({ style }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1024 1024'
    style={style}
  >
    <path
      d='M910.22 0C973.06 0 1024 50.94 1024 113.78v796.44c0 62.84-50.94 113.78-113.78 113.78H113.78C50.94 1024 0 973.06 0 910.22V113.78C0 50.94 50.94 0 113.78 0h796.44m-28.44 881.78V580.27c0-102.43-83.03-185.46-185.46-185.46-48.36 0-104.68 29.58-131.98 73.96v-63.15H405.62v476.16h158.72V601.32c0-43.8 35.27-79.64 79.08-79.64s79.64 35.66 79.64 79.64v280.46h158.72M220.73 316.3c52.78 0 95.57-42.79 95.57-95.57 0-52.91-42.67-96.14-95.57-96.14s-96.14 43.04-96.14 96.14c0 52.91 43.24 95.57 96.14 95.57m79.08 565.48V405.62H142.22v476.16H299.8Z'
      style={{
        fill: '#fff'
      }}
    />
  </svg>
)

export default LinkedInIcon
