import React, { SVGProps } from 'react'
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

export type CustomIconType = 'RightArrow' | 'Plus' | 'ChevronDown'

export interface CustomIconProps {
  size: number
  component: CustomIconType
  style?: React.CSSProperties
  props?: Partial<CustomIconComponentProps>
  fillColor?: SVGProps<SVGSVGElement>['fill']
  onClick?: () => void
}
const RightArrowSVG: React.FC = () => (
  <svg width='100%' height='100%' viewBox='0 0 56 64'>
    <path d='M52.867 33.7365C54.3737 32.2229 54.3737 29.7646 52.867 28.251L33.5812 8.87598C32.0746 7.3623 29.6277 7.3623 28.121 8.87598C26.6143 10.3896 26.6143 12.8479 28.121 14.3615L40.8375 27.1248H3.85714C1.72366 27.1248 0 28.8564 0 30.9998C0 33.1432 1.72366 34.8748 3.85714 34.8748H40.8254L28.133 47.6381C26.6263 49.1518 26.6263 51.61 28.133 53.1236C29.6397 54.6373 32.0866 54.6373 33.5933 53.1236L52.879 33.7486L52.867 33.7365Z' />
  </svg>
)

const PlusSVG: React.FC = () => (
  <svg width='100%' height='100%' viewBox='0 0 56 64'>
    <path d='M32 54C32 56.2125 30.2125 58 28 58C25.7875 58 24 56.2125 24 54V36H6C3.7875 36 2 34.2125 2 32C2 29.7875 3.7875 28 6 28H24V10C24 7.7875 25.7875 6 28 6C30.2125 6 32 7.7875 32 10V28H50C52.2125 28 54 29.7875 54 32C54 34.2125 52.2125 36 50 36H32V54Z' />
  </svg>
)

const ChevronSVG: React.FC = () => (
  <svg width='100%' height='100%' viewBox='0 0 64 64'>
    <path fill-rule='evenodd' clip-rule='evenodd' d='M8.16466 17.3931C9.60443 16.1033 11.8172 16.2249 13.107 17.6647L32 38.7546L50.8931 17.6647C52.1829 16.2249 54.3956 16.1033 55.8354 17.3931C57.2752 18.6829 57.3967 20.8956 56.107 22.3354L34.607 46.3354C33.943 47.0765 32.995 47.5 32 47.5C31.005 47.5 30.057 47.0765 29.3931 46.3354L7.89311 22.3354C6.60332 20.8956 6.7249 18.6829 8.16466 17.3931Z' />
  </svg>

)

const ComponentIcon: React.FC<CustomIconProps> = ({ size, component, style, props, fillColor, onClick }) => {
  const Component = component === 'RightArrow' ? RightArrowSVG : component === 'Plus' ? PlusSVG : component === 'ChevronDown' ? ChevronSVG : undefined

  return (
    <Icon
      onClick={onClick}
      component={Component}
      style={{ width: size, fill: fillColor, ...style }}
      {...props}
    />
  )
}

export default ComponentIcon
