import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { headerHeight } from "../components/Header"
import { SubTitle, Text } from "../components/Titles"
import { getFirestore, collection, doc } from "firebase/firestore"
import { getApp } from "firebase/app"
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore"
import Button from "../components/Button"
import {
  createPost,
  updatePost,
  updatePostOrder,
  UserRecord,
} from "../utils/firebase"
import Alert from "../components/Alert"
import UserTable from "../components/UserTable"
import { Post } from "../../types"
import { v4 as uuid } from "uuid"
import { useNavigate } from "react-router-dom"
import Loading from "../components/Loading"
import BreadCrumb from "../components/BreadCrumb"
// @ts-ignore
import Switch from "react-ios-switch"
import DraggableList from "../components/DraggableList"
import { IsAdminContext } from "../App"
import Colors from "../utils/colors.json"
import ProjectsGraph from "../components/ProjectsGraph"

const ContainerTop = styled.div`
  display: flex;
  margin-top: ${headerHeight}px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: ${headerHeight}px;
  padding: 0 40px;
  box-sizing: border-box;
`

const PostContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #000;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-left: 32px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`

const Spacer = styled.div`
  flex: 1;
  min-width: 20px;
`

const Admin: React.FC = () => {
  const [firebaseUsers, loadingUsers, errorUsers] = useCollection(
    collection(getFirestore(getApp()), "users")
  )
  const users = firebaseUsers?.docs.map((doc) => doc.data()) as
    | UserRecord[]
    | undefined
  const [firebasePosts, loadingPosts, errorPosts] = useCollection(
    collection(getFirestore(getApp()), "posts")
  )
  const posts = firebasePosts?.docs.map((doc) => doc.data()) as
    | Post[]
    | undefined
  const [firebasePostsOrder, loadingPostsOrder, errorPostsOrder] =
    useDocumentData(doc(getFirestore(getApp()), "sorting", "posts"))
  const postOrdering = firebasePostsOrder?.order as string[]
  const navigate = useNavigate()
  const [reorderedPosts, setReorderedPosts] = useState<Post[]>([])
  const isAdmin = useContext(IsAdminContext)

  useEffect(() => {
    const reorderPosts = async (): Promise<void> => {
      await updatePostOrder(reorderedPosts.map((p) => p.id))
    }

    if (reorderedPosts.length > 0) {
      reorderPosts().catch((e) => alert(e))
    }
  }, [reorderedPosts])

  useEffect(() => {
    if (reorderedPosts.length !== 0) {
      setReorderedPosts([])
    }
  }, [postOrdering, reorderedPosts.length])

  if (!isAdmin) {
    navigate("/login")
  }

  const create = async (): Promise<void> => {
    const title = await prompt("Enter a project name")
    const id = uuid()
    if (title == null) {
      return
    }
    const post: Post = {
      id,
      title,
      description: "",
      thumbnail: "",
      published: false,
      titleColor: "#fff",
      componentOrder: [],
      stats: {
        numberOfVideos: null,
        numberOfInstagramPosts: null,
        start: null,
        end: new Date().toISOString(),
        clientName: "",
        clientLink: "",
        tags: [],
        postClicks: 0,
      },
    }
    try {
      await createPost(post)
      navigate("/admin/post/" + String(id))
    } catch (e) {
      console.log(e)
    }
  }

  // Order posts by the order defined i the database
  let orderedData =
    postOrdering != null
      ? posts?.sort(
          (a, b) => postOrdering.indexOf(a.id) - postOrdering.indexOf(b.id)
        )
      : []
  if (orderedData == null) {
    orderedData = []
  }

  // loop through all posts and sort number of posts per year
  const years: Array<{ year: number; count: number }> = []
  posts?.forEach((p) => {
    const year = new Date(p.stats.end).getFullYear()
    const yearIndex = years.findIndex((y) => y.year === year)
    if (yearIndex === -1) {
      years.push({ year, count: 1 })
    } else {
      years[yearIndex].count++
    }
    // sort ascending
    years.sort((a, b) => a.year - b.year)
  })

  return (
    <>
      <ContainerTop>
        {errorPostsOrder != null && (
          <Alert type='warning'>{errorPostsOrder}</Alert>
        )}
        <BreadCrumb
          style={{ alignSelf: "flex-start", marginLeft: "40px" }}
          crumbs={[{ url: "/admin", title: "admin" }]}
        />
      </ContainerTop>
      <Container>
        <Column>
          <SubTitle>Ghosts</SubTitle>
          {errorUsers != null && (
            <Alert type='warning'>
              {errorUsers.message.replace("Firebase: ", "")}
            </Alert>
          )}
          {users != null && <UserTable users={users} />}
          {loadingUsers && <Loading />}
        </Column>
        <Column>
          <Row>
            <SubTitle>Projects</SubTitle>
            {/* Antdesign icon with a plus icon as component */}
            <Button
              onClick={create}
              customIcon='Plus'
              iconSize={20}
              style={{ flexDirection: "row-reverse", padding: "10px 13px" }}
              color={Colors.GM_Orange}
            >
              ADD
            </Button>
          </Row>

          {errorPosts != null && (
            <Alert type='warning'>
              {errorPosts.message.replace("Firebase: ", "")}
            </Alert>
          )}
          {(loadingPosts || loadingPostsOrder) && <Loading />}

          {posts != null && (
            <DraggableList
              lockX={false}
              onChange={(data) => setReorderedPosts(data as Post[])}
              data={reorderedPosts.length > 0 ? reorderedPosts : orderedData}
              keyExtractor={(data) => data.id}
              renderItem={(data) => {
                return (
                  <PostContainer
                    onClick={() => navigate("/admin/post/" + String(data.id))}
                    id={data.id}
                    key={data.id}
                  >
                    <SubTitle style={{ margin: 0, whiteSpace: "nowrap" }}>
                      {data.title}
                    </SubTitle>
                    <Spacer />
                    <Switch
                      checked={data.published}
                      onChange={async (value: boolean) =>
                        await updatePost(data.id, { published: value })
                      }
                    />
                    {/* <DragHandle /> */}
                  </PostContainer>
                )
              }}
            />
          )}
        </Column>
        <Column>
          <SubTitle>Info</SubTitle>
          <ProjectsGraph inData={years} />
          <Text>
            Projects: {posts?.length} (Active:{" "}
            {posts?.filter((p) => p.published).length})
          </Text>
        </Column>
      </Container>
    </>
  )
}

export default Admin
