import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import GhostIcon from './icons/GhostIcon'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: 40%;
`

const Container = styled.div`
  width: 50px;
`

const transR = (r: number): string => `rotateZ(${r}deg)`
const transX = (x: number): string => `translateX(${x}px)`
const transY = (y: number): string => `translateY(${y}px)`

const Loading: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const [x, setX] = useSpring(() => ({
    x: 12,
    config: {
      mass: 5,
      tension: 10,
      friction: 0
    }
  }))
  const [y, setY] = useSpring(() => ({
    y: 15,
    config: {
      mass: 6,
      tension: 15,
      friction: 0
    }
  }))
  const [r, setR] = useSpring(() => ({
    r: 5,
    config: {
      mass: 6,
      tension: 20,
      friction: 0
    }
  }))

  useEffect(() => {
    setX({ x: 0 })
    setY({ y: 0 })
    setR({ r: 0 })
  }, [setX, setY, setR])

  return (
    <Center>
      <Container style={style}>
        <animated.div
          style={
          { transform: r.r.to(transR) }
        }
        >
          <animated.div
            style={
            { transform: y.y.to(transY) }
          }
          >
            <animated.div
              style={{ transform: x.x.to(transX) }}
            >
              <GhostIcon />
            </animated.div>
          </animated.div>
        </animated.div>
      </Container>
    </Center>
  )
}

export default Loading
