import React from "react"
import styled from "styled-components"
import { Post } from "../../types"

const StyledProject = styled.div<{ highlighted: boolean }>`
  display: flex;
  position: relative;

  // height: calc(1.72 * 2vw);

  // max-height: 83px;
  opacity: ${(props: { highlighted: boolean }) =>
    props.highlighted ? 1 : 0.2};
  transition: 250ms;
  transition-timing-function: ease-out;
  cursor: pointer;
  /* scroll-snap-align: ${(props: { highlighted: boolean }) =>
    props.highlighted ? "none" : "center"}; */

  margin-left: ${(props: { highlighted: boolean }) =>
    props.highlighted ? 0.5 : 0}em;
  text-align: left;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`
const ProjectTitletext = styled.div<{
  color1: string
  color2: string
  highlighted: boolean
}>`
  margin: 0;
  font-size: clamp(16px, 3vw, 40px);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2;
  background: ${(props: { color1: string }) => props.color1};
  background: radial-gradient(
    ellipse farthest-side at top right,
    ${(props: { color2: string; highlighted: boolean }) =>
        props.highlighted ? props.color2 : "#222222"}
      0%,
    ${(props: { color1: string; highlighted: boolean }) =>
        props.highlighted ? props.color1 : "#222222"}
      100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`
const PostTags = styled.div<{ highlighted: boolean }>`
  opacity: ${(props: { highlighted: boolean }) => (props.highlighted ? 1 : 0)};
  font-size: clamp(12px, 1.9vw, 16px);
  text-transform: uppercase;
  font-weight: normal;
  margin-right: 0.3em;
`
const PostYear = styled.div<{ highlighted: boolean }>`
  opacity: ${(props: { highlighted: boolean }) => (props.highlighted ? 1 : 0)};
  font-size: clamp(12px, 1vw, 16px);
  text-transform: uppercase;
  font-weight: bold;
`
const Divider = styled.div<{ highlighted: boolean }>`
  transition: 250ms;
  transition-delay: 250ms;
  opacity: ${(props: { highlighted: boolean }) => (props.highlighted ? 1 : 0)};
  font-size: clamp(24px, 3vw, 50px);
  text-transform: uppercase;
  font-weight: normal;
  margin-left: ${(props: { highlighted: boolean }) =>
    props.highlighted ? 0.2 : 0}em;
  transition-timing-function: ease-in-out;
`
const Column = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-timing-function: ease-in-out;
  transition: 500ms;
  transition-delay: 250ms;
  opacity: ${(props: { highlighted: boolean }) => (props.highlighted ? 1 : 0)};
  margin-left: ${(props: { highlighted: boolean }) =>
    props.highlighted ? 0.5 : -0.2}em;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const ProjectPreview: React.FC<{
  post: Post
  highlighted?: boolean
  onClick: (e: React.UIEvent<HTMLDivElement>) => void
}> = ({ post, highlighted = false, onClick }) => {
  const breaker: string = " / "

  function changeColor(color: string, amount: number): string {
    // #FFF not supportet rather use #FFFFFF
    const clamp = (val: any): Number => Math.min(Math.max(val, 0), 0xff)
    const fill = (str: string): string => ("00" + str).slice(-2)

    // convert to color string to number
    const num = parseInt(color?.substring(1), 16)
    const red = clamp((num >> 16) + amount)
    const green = clamp(((num >> 8) & 0x00ff) + amount)
    const blue = clamp((num & 0x0000ff) + amount)
    return (
      "#" +
      fill(red.toString(16)) +
      fill(green.toString(16)) +
      fill(blue.toString(16))
    )
  }

  return (
    <StyledProject highlighted={highlighted} onClick={onClick}>
      <ProjectTitletext
        color1={post.titleColor}
        color2={changeColor(post.titleColor, 70)}
        highlighted={highlighted}
      >
        {post.title}
      </ProjectTitletext>
      <Divider highlighted={highlighted}>/</Divider>
      <Column highlighted={highlighted}>
        <Row>
          {post.stats?.tags?.map((tag, i) => (
            <PostTags key={i} highlighted={highlighted}>
              {" "}
              {tag + (i + 1 === post.stats.tags.length ? " " : breaker)}{" "}
            </PostTags>
          ))}
        </Row>
        <PostYear highlighted={highlighted}>
          {post.stats?.end?.split("-")[0]}
        </PostYear>
      </Column>
    </StyledProject>
  )
}

export default ProjectPreview
