import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"
import React from "react"
import styled from "styled-components"
import { Post } from "../../types"
import { updatePostStats } from "../utils/firebase"
import Button from "./Button"
import Colors from "../utils/colors.json"

const Container = styled.div`
  flex: 5;
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
  background-color: none;
  transition: 200ms;
  :hover {
    background-color: ${Colors.GM_Grey};
  }
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0;
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
  background-color: ${Colors.GM_Grey_Light};
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Hover = styled.div`
  transition: all 0.2s ease-in-out;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    color: #b82b2b;
  }
`
const Tag: React.FC<{ tag: string; onClick: (tag: string) => void }> = ({
  tag,
  onClick,
}) => {
  return (
    <TagContainer>
      {tag}
      <Hover>
        <CloseCircleOutlined
          size={20}
          color='#fff'
          onClick={() => onClick(tag)}
        />
      </Hover>
    </TagContainer>
  )
}

export interface TagsProps {
  post: Post
}

const Tags: React.FC<TagsProps> = ({ post }) => {
  const addTag = async (): Promise<void> => {
    const tag = prompt("Enter a tag")
    if (tag != null) {
      try {
        if (post.stats.tags.includes(tag)) throw new Error("Tag already exists")
        await updatePostStats(post.id, {
          tags: [...post.stats.tags, tag],
        })
      } catch (e) {
        alert(e)
      }
    }
  }

  const removeTag = async (tag: string): Promise<void> => {
    try {
      await updatePostStats(post.id, {
        tags: post.stats.tags.filter((t) => t !== tag),
      })
    } catch (e) {
      alert(e)
    }
  }

  return (
    <Container>
      <List>
        <TagsWrapper>
          {post.stats.tags.map((tag, index) => (
            <Tag key={tag} tag={tag} onClick={removeTag} />
          ))}
        </TagsWrapper>
        <Button
          iconType={PlusOutlined}
          color={Colors.GM_Orange}
          onClick={addTag}
          style={{
            margin: 0,
            width: "100px",
            height: "20px",
            padding: "5px 10px",
            flexDirection: "row-reverse",
          }}
        >
          Add tag
        </Button>
      </List>
    </Container>
  )
}

export default Tags
