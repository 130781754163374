import { MeshPhysicalMaterial } from "three/src/materials/MeshPhysicalMaterial.js"
import * as THREE from "three/src/math/MathUtils"
import { Mesh } from "three/src/objects/Mesh"

import { Environment, useGLTF, useTexture } from "@react-three/drei/core"
import { Canvas, useFrame, useThree } from "@react-three/fiber/"
import { Bloom, EffectComposer } from "@react-three/postprocessing"
import React, { useRef, useState } from "react"
import { GLTF } from "three-stdlib/loaders/GLTFLoader"

export type GLTFResult = GLTF & {
  nodes: {
    mesh: Mesh
  }
  materials: {
    ghost: MeshPhysicalMaterial
  }
  index: number
  speed: number
  z: number
}

export function Ghost(props: any): JSX.Element {
  const ref = useRef<Mesh>(null)
  const { index, speed, z } = props as GLTFResult
  // useThree gives you access to the R3F state model
  const { viewport, camera } = useThree()
  // getCurrentViewport is a helper that calculates the size of the viewport
  const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z])
  // useGLTF is an abstraction around R3F's useLoader(GLTFLoader, url)
  // It can automatically handle draco and meshopt-compressed assets without you having to
  // worry about binaries and such ...

  // import ghost model
  const { nodes } = useGLTF("./ghost.glb") as GLTFResult

  // By the time we're here the model is loaded, this is possible through React suspense

  // Local component state, it is safe to mutate because it's fixed data
  const [data] = useState({
    // Randomly distributing the objects along the vertical
    y: THREE.MathUtils.randFloatSpread(height * 2),
    // This gives us a random value between -1 and 1, we will multiply it with the viewport width
    x: THREE.MathUtils.randFloatSpread(2),
    // How fast objects spin, randFlost gives us a value between min and max, in this case 8 and 12
    spin: THREE.MathUtils.randFloat(8, 12),
    // Some random rotations, Math.PI represents 360 degrees in radian
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI,
  })

  // useFrame executes 60 times per second
  useFrame((state, dt) => {
    // Make the X position responsive, slowly scroll objects up at the Y, distribute it along the Z
    // dt is the delta, the time between this frame and the previous, we can use it to be independent of the screens refresh rate
    // We cap dt at 0.1 because now it can't accumulate while the user changes the tab, it will simply stop

    // if ref is not undefined, set the position of the mesh
    if (ref.current !== null) {
      if (dt < 0.1)
        ref.current?.position.set(
          index === 0 ? 0 : data.x * width,
          (data.y += dt * speed),
          -z
        )
      // Rotate the object around
      ref.current.rotation.set(
        (data.rX += dt / data.spin),
        Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI,
        (data.rZ += dt / data.spin)
      )
      // If they're too far up, set them back to the bottom
      if (data.y > height * (index === 0 ? 4 : 1))
        data.y = -(height * (index === 0 ? 4 : 1))
    }
  })

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ref}
        geometry={nodes.mesh.geometry}
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[2, 2, 2]}
        castShadow
        receiveShadow
      >
        <meshPhysicalMaterial
          attach='material'
          color='#00bcff'
          metalness={0.8}
          roughness={0}
          clearcoat={1}
          clearcoatRoughness={0}
          transmission={1}
          opacity={0.5}
          reflectivity={1}
          envMapIntensity={1}
        />
      </mesh>
    </group>
  )
}

const ImagePlane = (props: any): JSX.Element => {
  const textureImage = useTexture("/GM_text.png")
  const ref = useRef<Mesh>(null)
  useFrame((state, delta) => {
    if (ref.current !== null) {
      ref.current.scale.x = Math.min((state.viewport.width / 10) * 3, 0.8)
      ref.current.scale.y = Math.min((state.viewport.width / 10) * 3, 0.8)
    }
  })

  return (
    <mesh ref={ref} position={props.position}>
      <planeGeometry args={[2000 / 197, 1]} />
      <meshBasicMaterial
        attach='material'
        map={textureImage}
        transparent
        opacity={1}
      />
    </mesh>
  )
}

export default function Ghosts({
  speed = 1,
  count = 50,
  depth = 80,
  easing = (x: any) => Math.sqrt(1 - Math.pow(x - 1, 2)),
}): JSX.Element {
  return (
    // No need for antialias (faster), dpr clamps the resolution to 1.5 (also faster than full resolution)
    <Canvas
      gl={{ antialias: false }}
      dpr={[1, 1.5]}
      camera={{ position: [0, 0, 10], fov: 12, near: 0.01, far: depth + 15 }}
    >
      {/* <color attach='background' args={['#13313c']} /> */}
      <spotLight
        position={[10, 20, 10]}
        penumbra={10}
        intensity={3}
        color='blue'
      />

      {/* Using cubic easing here to spread out objects a little more interestingly, i wanted a sole big object up front ... */}
      {Array.from({ length: count }, (_, i) => (
        <Ghost key={i} index={i} speed={speed} z={depth * easing(i / count)} />
      ))}
      {/* <Text position={[0, 0, -25]} fontSize={(visualViewport != null) ? visualViewport?.width / 1000 : 1.2} color='blue'>GHOST MOTION </Text> */}
      <ImagePlane position={[0, 0, -40]} />
      <Environment preset='city' />
      {/* Multisampling (MSAA) is WebGL2 antialeasing, we don't need it (faster) */}
      <EffectComposer multisampling={0}>
        {/* <DepthOfField target={[0, 0, -15]} focalLength={0.8} bokehScale={5} height={700} /> */}
        {/* bloom */}
        <Bloom luminanceThreshold={0.8} luminanceSmoothing={0.9} height={500} />
      </EffectComposer>
    </Canvas>
  )
}

useGLTF.preload("/ghost.glb")
