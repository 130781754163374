import React from "react"
import styled from "styled-components"
import GM_icon from "../img/GM_icon_white.svg"

const CarouselSlide = styled.div<{ animationname: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 200%;
  transform: translate3d(0, 0, 0);
  animation: ${(props) => props.animationname} 10s linear infinite;
`

const CarouselWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  align-items: center;

  // Animations
  @keyframes scrollRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes scrollLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`
const Carousel = styled.div`
  display: flex;
  padding: 25px 0;
  background: none;
`

const Text = styled.div`
  display: flex;
  margin: 0;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px;
`
const Logo = styled.img`
  display: flex;
  width: 33px;
  height: auto;
  padding: 0 20px;
`

const InfiniteScroller: React.FC = () => {
  const elementsLeft: React.ReactNode[] = []
  for (let index = 0; index < 6; index++) {
    elementsLeft.push(
      <CarouselSlide animationname='scrollRight' key={index}>
        <Text>Photo</Text>
        <Logo src={GM_icon} />
        <Text>Film</Text>
        <Logo src={GM_icon} />
        <Text>Design</Text>
        <Logo src={GM_icon} />
      </CarouselSlide>
    )
  }

  const elementsRight: React.ReactNode[] = []
  for (let index = 0; index < 6; index++) {
    elementsRight.push(
      <CarouselSlide animationname='scrollLeft' key={index}>
        <Text>Photo</Text>
        <Logo src={GM_icon} />
        <Text>Film</Text>
        <Logo src={GM_icon} />
        <Text>Design</Text>
        <Logo src={GM_icon} />
      </CarouselSlide>
    )
  }
  return (
    <CarouselWrapper>
      <Carousel>{elementsLeft}</Carousel>

      <Carousel>{elementsRight}</Carousel>
    </CarouselWrapper>
  )
}

export default InfiniteScroller
